import propTypes from 'prop-types';
import { ChevronLeft, ChevronRight } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { KiperButton } from '@kiper/ui';
import { useMemo } from 'react';
import { useDebounce } from '@kiper/hooks';
import { Container, FormGroup, Label, SelectPageSize } from './styles';

const Pagination = ({
  pagination,
  handlePageSize,
  next,
  previous,
  loadingFilter,
}) => {
  const {
    hasNext,
    hasPrevious,
    page,
    pageSize,
    totalResults,
    totalPages,
  } = pagination;

  const [t] = useTranslation('common');
  const debounce = useDebounce({ delay: 200 });

  const from = (page - 1) * pageSize + 1;
  const to = (page - 1) * pageSize + pageSize;

  const infoPageText = `${from}-${to > totalResults ? totalResults : to} ${t(
    'pagination.of',
  )} ${totalResults}`;

  const infoPage = useMemo(() => {
    if (from && to) {
      return infoPageText;
    }
    return null;
  }, [from, to]);

  const handleNextPage = () => {
    debounce(() => next());
  };

  const handlePreviousPage = () => {
    debounce(() => previous());
  };

  return (
    <Container>
      <FormGroup>
        <Label for="letterPage">{t('pagination.lines-per-page')}</Label>
        <SelectPageSize
          type="select"
          name="pagesize"
          value={pageSize}
          onChange={e => handlePageSize(e ? e.target.value : 10)}
        >
          <option value="10">10</option>
          <option value="25">25</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </SelectPageSize>

        {totalResults !== -1 && infoPage && <Label>{infoPage}</Label>}
        <KiperButton
          disabled={
            loadingFilter ||
            (hasPrevious && hasPrevious === 'false') ||
            !page ||
            page === 1
          }
          variant="text"
          color="secondary"
          name="page"
          rounded
          icon={<ChevronLeft />}
          value={page}
          onClick={handlePreviousPage}
        />
        <KiperButton
          disabled={
            loadingFilter ||
            (hasNext && hasNext === 'false') ||
            totalPages === page
          }
          variant="text"
          color="secondary"
          name="page"
          value={page}
          onClick={handleNextPage}
          rounded
          icon={<ChevronRight />}
        />
      </FormGroup>
    </Container>
  );
};

export default Pagination;

Pagination.propTypes = {
  pagination: propTypes.shape({
    pageSize: propTypes.number,
    totalResults: propTypes.number,
    page: propTypes.number,
    totalPages: propTypes.number,
    hasNext: propTypes.string,
    hasPrevious: propTypes.string,
  }),
  handlePageSize: propTypes.func.isRequired,
  next: propTypes.func.isRequired,
  previous: propTypes.func.isRequired,
  loadingFilter: propTypes.bool,
};

Pagination.defaultProps = {
  loadingFilter: false,
  pagination: {
    pageSize: 0,
    totalResults: 0,
    page: 0,
    totalPages: 0,
  },
};
