import { useEffect, useContext, useState, useCallback } from 'react';
import { firstBy } from 'thenby';
import { useLazyQuery } from 'react-apollo';
import { getContinuousAttendance } from '@kiper/monitoring-graphql/guided_attendance';
import { SocketContext } from '../services/socket';

const continuousAttendanceAction = Object.freeze({
  NEW_CONTINUOUS_ATTENDANCE: 0,
  UPDATE_CONTINUOUS_ATTENDANCE: 1,
});

export const continuousAttendanceStatus = Object.freeze({
  ONGOING: 'OnGoing',
  AUTHORIZED: 'Authorized',
  NOT_AUTHORIZED: 'NotAuthorized',
  UNAVAILABLE_CONTACTS: 'UnavailableContacts',
  AT_THE_CONCIERGE: 'AtTheConcierge',
});

export default function useContinuousAttendance({
  attendances,
  condominiumPersonContextId,
}) {
  const { subscribe, unsubscribe, sendMessage } = useContext(SocketContext);
  const [continuousAttendances, setContinuousAttendances] = useState(
    attendances || [],
  );

  const [getData, { loading }] = useLazyQuery(getContinuousAttendance, {
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      if (data?.continuousAttendance?.attendances?.length)
        setContinuousAttendances(data?.continuousAttendance?.attendances);
    },
  });

  useEffect(() => {
    if (!attendances?.length) {
      getData({
        variables: {
          condominiumContextId: condominiumPersonContextId,
        },
      });
    }
  }, []);

  const onMessage = ({ action, item }) => {
    switch (action) {
      case continuousAttendanceAction.NEW_CONTINUOUS_ATTENDANCE:
        setContinuousAttendances(continuous => [...continuous, item]);
        break;
      case continuousAttendanceAction.UPDATE_CONTINUOUS_ATTENDANCE:
        setContinuousAttendances(previousContinuous =>
          previousContinuous.map(continuous =>
            continuous.eventId === item.eventId ? item : continuous,
          ),
        );
        break;
      default:
        break;
    }
  };

  const handleSort = useCallback(
    continuousAttendanceList =>
      continuousAttendanceList?.sort(firstBy('creationDate', 'desc')),
    [attendances],
  );

  useEffect(() => {
    let channel;

    if (condominiumPersonContextId) {
      channel = `condominium.${condominiumPersonContextId}`;
      subscribe(channel, onMessage);
      sendMessage('continuousAttendance', {
        channel,
      });
    }
    return () => {
      unsubscribe(channel);
    };
  }, []);

  return {
    continuousAttendances: handleSort(continuousAttendances),
    loading,
  };
}
