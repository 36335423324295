import { useContext } from 'react';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { TabContent } from '@kiper/ui';
import { useCurrentLoggedContext, useRemoteConfig } from '../../hooks';
import PageHeader from '../../components/PageHeader';
import TabHeaderPage from '../../components/TabHeaderPage';
import {
  GuidedAttendanceAttendants,
  GuidedAttendanceCondominiums,
  GuidedAttendancePartners,
  HeaderButtons,
} from './components';
import {
  GuidedAttendanceSettingsTabContext,
  GuidedAttendanceSettingsTabProvider,
} from './store';

const GuidedAttendanceSettingsWrapper = props => {
  return (
    <GuidedAttendanceSettingsTabProvider>
      <GuidedAttendanceSettings {...props} />
    </GuidedAttendanceSettingsTabProvider>
  );
};

const GuidedAttendanceSettings = ({ route, history }) => {
  const { loggedContext } = useCurrentLoggedContext();
  const { activeTab, handleChangeTab, tabs, tabIds } = useContext(
    GuidedAttendanceSettingsTabContext,
  );
  const [t] = useTranslation('guided-attendance-settings');

  const showGuidedAttendanceSettings = useRemoteConfig({
    path: 'guidedAttendanceSettings.showSettings',
    partner: Number(loggedContext?.partner?.personContextId),
    user: Number(loggedContext?.personContextId),
  });

  if (!showGuidedAttendanceSettings) {
    history.push('/');
  }

  return (
    <>
      <PageHeader
        breadcrumb={route.breadcrumb}
        t={t}
        title={t('list.header-title')}
        tabHeaderPage={
          <TabHeaderPage
            tabs={tabs}
            activeTab={activeTab}
            onChangeTab={handleChangeTab}
          />
        }
        headerButton={<HeaderButtons history={history} t={t} />}
      />
      <TabContent activeTab={activeTab}>
        {activeTab === tabIds.CONDOMINIUMS && <GuidedAttendanceCondominiums />}
        {activeTab === tabIds.ATTENDANTS && <GuidedAttendanceAttendants />}
        {activeTab === tabIds.PARTNERS && <GuidedAttendancePartners />}
      </TabContent>
    </>
  );
};

export default GuidedAttendanceSettingsWrapper;

GuidedAttendanceSettings.propTypes = {
  route: propTypes.object.isRequired,
  history: propTypes.object.isRequired,
};
