import { useContext, useEffect, useMemo } from 'react';
import propTypes from 'prop-types';
import { useFormikContext } from 'formik';
import * as yup from 'yup';
import { Trans, useTranslation } from 'react-i18next';
import { Flex, Input, ErrorMessage, AlertBar, MdIcons } from '@kiper/ui';
import { getGreetingTime } from '@kiper/fns';
import {
  useGetEventDeviceWay,
  useGuidedAttendance,
  useInsertStepHistory,
} from '../../../hooks';
import { GuidedAttendanceContext, TriageContext } from '../../../store';
import StepStructure, { StepTextTypeColor } from '../StepStructure';
import { Label } from '../styles';

const StepAttendanceGetName = ({ event }) => {
  const { setContextTree } = useContext(GuidedAttendanceContext);
  const { setTriageContext } = useContext(TriageContext);
  const { handleNextAction } = useGuidedAttendance();
  const { handleInsertStepHistory } = useInsertStepHistory();
  const { values, setFieldValue, errors, touched } = useFormikContext();
  const { t } = useTranslation('guided-attendance');
  const schema = yup.object({
    stepAttendanceGetName: yup.object({
      name: yup
        .string()
        .trim()
        .min(3, t('common:feedback.min', { number: 3 }))
        .required(t('common:feedback.required-field')),
      oldName: yup
        .string()
        .trim()
        .nullable(),
    }),
  });

  const greet = useMemo(() => getGreetingTime(new Date()), []); // TODO: Esperar vir o GMT do eventSingle pra passar o segundo param do getGreetingTime

  const { exitDirection } = useGetEventDeviceWay(event);

  const submitCurrentStepHistory = (steps, eventId) => {
    const { stepAttendanceGetName } = steps;
    const currentPersonName = stepAttendanceGetName?.name;
    const oldPersonName = stepAttendanceGetName?.oldName;

    const data = {
      currentPersonName,
      oldPersonName,
    };

    handleInsertStepHistory(data, eventId);
  };

  const nextAction = async value => {
    if (value?.stepAttendanceGetName?.name)
      setTriageContext({
        identifier: value.stepAttendanceGetName.name,
      });

    submitCurrentStepHistory(value, event.eventId);

    const handleNextFn = await handleNextAction({
      values: value,
      fieldValue: 'stepAttendanceGetName',
    });

    return handleNextFn;
  };

  useEffect(() => {
    if (!values?.stepAttendanceGetName?.name) {
      setFieldValue('stepAttendanceGetName', { name: '', oldName: null });
    }
    setContextTree({
      formikSchema: schema,
      nextAction: async stepData => {
        const nextStep = await nextAction(stepData);
        return { nextStep, currentStepData: stepData };
      },
    });
  }, []);

  return (
    <>
      {exitDirection && (
        <AlertBar
          width="100%"
          color="black"
          background={StepTextTypeColor.WARNING.background}
          title={<Trans>{t('step-get-name.call-made-by-outgoing')}</Trans>}
          icon={<MdIcons mdIconName="warning" size="20px" />}
        />
      )}
      <StepStructure
        type={StepTextTypeColor.SPEAK}
        text={t('step-get-name.title', { period: t(`step-get-name.${greet}`) })}
        hasNextStepButton
      >
        <Flex flexDirection="column" width="100%">
          <Label>{t('step-get-name.label')}</Label>
          <Input
            placeholder={t('step-get-name.placeholder')}
            name="stepAttendanceGetName.name"
            value={values?.stepAttendanceGetName?.name}
            onChange={setFieldValue}
            autoFocus
            autoComplete="off"
            invalid={
              !!touched?.stepAttendanceGetName &&
              !!errors?.stepAttendanceGetName?.name
            }
          />
          {!!touched?.stepAttendanceGetName?.name &&
            !!errors?.stepAttendanceGetName?.name && (
              <ErrorMessage>{errors.stepAttendanceGetName.name}</ErrorMessage>
            )}
        </Flex>
      </StepStructure>
    </>
  );
};

export default StepAttendanceGetName;

StepAttendanceGetName.propTypes = {
  event: propTypes.object.isRequired,
};
