import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import PlayerButtonContent from '../PlayerButtonContent';

const PlayerActionButton = ({
  photo,
  loadingLive,
  errorLoadingImg,
  capturingPhoto,
  loadingSubmitPhoto,
  photoSent,
  values,
}) => {
  const { t } = useTranslation('guided-attendance');

  if (!photo) {
    if (!loadingLive && errorLoadingImg) {
      return <PlayerButtonContent text={t('step-photo-register.skip-step')} />;
    }

    if (capturingPhoto) {
      return (
        <PlayerButtonContent
          text={t('step-photo-register.capturing-photo')}
          icon="sending"
          iconRight
        />
      );
    }

    return (
      <PlayerButtonContent
        text={t('step-photo-register.capture-photo')}
        icon="camera"
      />
    );
  }

  if (loadingSubmitPhoto) {
    return (
      <PlayerButtonContent
        text={t('step-photo-register.sending')}
        icon="sending"
        iconRight
      />
    );
  }

  if (!values?.stepPhotoRegister?.photoRegistered) {
    return (
      <PlayerButtonContent
        text={t('step-photo-register.send-photo')}
        icon="sendPhoto"
      />
    );
  }

  if (photoSent) {
    return (
      <PlayerButtonContent text={t('step-photo-register.sent')} icon="sent" />
    );
  }

  return (
    <PlayerButtonContent text={t('step-photo-register.resend')} icon="resend" />
  );
};

PlayerActionButton.propTypes = {
  photo: PropTypes.string,
  loadingLive: PropTypes.bool,
  errorLoadingImg: PropTypes.bool,
  capturingPhoto: PropTypes.bool,
  loadingSubmitPhoto: PropTypes.bool,
  photoSent: PropTypes.bool,
  values: PropTypes.object,
};

PlayerActionButton.defaultProps = {
  photo: null,
  loadingLive: false,
  errorLoadingImg: false,
  capturingPhoto: false,
  loadingSubmitPhoto: false,
  photoSent: false,
  values: {},
};

export default PlayerActionButton;
