import {
  FiClock,
  FiCornerUpLeft,
  FiCpu,
  FiMessageSquare,
  FiPhoneIncoming,
  FiPhoneOutgoing,
  FiPlay,
  FiSquare,
  FiStopCircle,
  FiTool,
  FiVideo,
} from 'react-icons/fi';
import { RiDoorClosedLine } from 'react-icons/ri';
import {
  MdOutlinePermPhoneMsg,
  MdUpdate,
  MdOutlineEngineering,
  MdOutlineErrorOutline,
} from 'react-icons/md';
import { Flex, KiperButton, MdIcons } from '@kiper/ui';
import { formatTimerLabel, getUserLanguageCode } from '@kiper/fns';
import {
  activityTypes,
  authorizationStatus,
  destinationTypes,
  dwellerValidationStatus,
  profileTypes,
  questionAnswerType,
  riskSituationDetectedStatus,
  triageTranslate,
} from '../../../constants';
import { ActivityTypeStatus, TreatmentText } from './styles';

const commandTypes = {
  OpenDoor: 'open',
  CloseDoor: 'close',
  KeepDoorOpen: 'keep-open',
  Regular: 'keep-open',
  Truck: 'truck',
  Cleaning: 'cleaning',
  OpenRelay: 'open-relay',
  CloseRelay: 'close-relay',
  PulseRelay: 'pulse-relay',
  ActivatePartition: 'activate-partition',
  DeactivatePartition: 'deactivate-partition',
};

const closureReasonTranslateKey = Object.freeze({
  lackOfCommunication: 'lack-of-communication',
  AlarmAcknowledgmentNoIssueFound: 'alarm-acknowledgment-no-issue-found',
  AlarmAcknowledgmentIssueFound: 'alarm-acknowledgment-issue-found',
});

const handleStepDwellerValidationTranslate = ({ params, t }) => {
  switch (params.dwellerValidationStatus) {
    case dwellerValidationStatus.INVALID:
      return t('step-dweller-validation.uninformed');
    case dwellerValidationStatus.VALID:
      return t('step-dweller-validation.valid', {
        documentType: t(
          `step-dweller-validation.documents.${params?.documentType}`,
        ),
      });
    case dwellerValidationStatus.NO_DWELLERS:
      return t('step-dweller-validation.no-dwellers');
    default:
      return '';
  }
};

const handleStepFindRegisterTranslate = ({ params, t }) => {
  const { status, name, profileName } = params;
  if (status !== authorizationStatus.UNREGISTERED && (!profileName || !name)) {
    return '';
  }

  const getProfileTranslation = profile =>
    t(`common:profiles.${profileTypes[profile]}`).toLowerCase();

  const getActivityTranslation = statusKey =>
    t('step-find-register.activity', {
      name,
      profile: getProfileTranslation(profileName),
      status: t(`step-find-register.status.${statusKey}`),
    });

  switch (status) {
    case authorizationStatus.UNREGISTERED:
      return t('step-find-register.status.unregistered');
    case authorizationStatus.AUTHORIZED:
      return getActivityTranslation('authorized');
    case authorizationStatus.REQUEST_AUTHORIZATION:
      return getActivityTranslation('request-authorization');
    default:
      return '';
  }
};

const translateCallStatus = ({ status, t }) => {
  const callStatus = {
    UnavailableContacts: t('unavailable-contacts'),
    Authorized: t('authorized'),
    NotAuthorized: t('not-authorized'),
    AtTheConcierge: t('at-the-concierge'),
  };

  return callStatus[status];
};

const handleStepGetSubtriageTranslate = ({ params, t }) => {
  const language = getUserLanguageCode();
  const subtriageDescription =
    params?.description?.[language] || params?.description?.pt;
  return t('step-get-subtriage.activity', {
    subtriage: subtriageDescription,
  });
};

const handleStepAttendanceGetName = ({ params, t }) => {
  return t('step-attendance-get-name.name', {
    name: params.currentPersonName,
  });
};

const handleStepStepAttendanceGetReasonTranslate = ({ params, t }) => {
  const language = getUserLanguageCode();
  const triageDescription =
    params?.description?.[language] || params?.description?.pt;

  if (triageDescription) {
    return t('step-attendance-get-reason.triage', {
      triage: triageDescription,
    });
  }

  return t('step-attendance-get-reason.triage', {
    triage: triageTranslate[params?.triageId]
      ? t(`common:triages.${triageTranslate[params?.triageId]}`)
      : '',
  });
};

const handleStepOpenDoors = ({ params, t }) => {
  if (params?.finish) {
    return t('step-open-doors.finish');
  }
  return t('step-open-doors.treatment', {
    treatment: params.treatment,
  });
};

const handleStepTransientPersonRegister = ({ params, t }) => {
  const keys = [
    'name',
    'companyName',
    'documentCpf',
    'documentRg',
    'contactNumber',
    'vehiclePlate',
  ];

  const filledFields = keys.reduce((acc, key) => {
    if (params?.[key]) {
      acc[key] = t(`step-register-transient-person.fields.${key}`, {
        [key]: params[key],
      });
    }
    return acc;
  }, {});

  return t('step-register-transient-person.fullFilled', {
    header: t('step-register-transient-person.header'),
    ...filledFields,
  });
};

const translateStepHistory = ({ step, params, t }) => {
  const stepHistory = {
    StepAttendanceGetUnity: t('step-history.get-unity', {
      unityGroup: params?.unityGroup,
      unity: params?.unity,
    }),
    StepGetOnlyUnity: t('step-history.get-unity', {
      unityGroup: params?.unityGroup,
      unity: params?.unity,
    }),
    StepDwellerValidation: handleStepDwellerValidationTranslate({ params, t }),
    StepAttendanceGetReason: handleStepStepAttendanceGetReasonTranslate({
      params,
      t,
    }),
    StepFindRegister: handleStepFindRegisterTranslate({ params, t }),
    StepGetSubtriage: handleStepGetSubtriageTranslate({ params, t }),
    StepAttendanceGetName: handleStepAttendanceGetName({ params, t }),
    StepOpenDoors: handleStepOpenDoors({ params, t }),
    StepOutOfHoursServiceProvider: t(
      'out-of-hour-service-provider.essential-service',
    ),
    StepRegisterServiceProvider: handleStepTransientPersonRegister({
      params,
      t,
    }),
    StepRegisterVisitorTransientPerson: handleStepTransientPersonRegister({
      params,
      t,
    }),
    StepPhotoRegister: t('step-transient-person-photo-capture'),
  };

  return stepHistory[step];
};

export const activityTypeControl = ({
  event,
  fetchPlayback,
  loadingShowPlayback,
  activityTypeId,
  additionalInformation,
  t,
  text,
}) => {
  const additional = additionalInformation && JSON.parse(additionalInformation);

  const untreatedEvent = additional?.untreatedEventOption;
  const closureReason = additional?.closureReason;

  switch (activityTypeId) {
    case activityTypes.ServiceOrder: {
      return {
        iconStatus: (
          <ActivityTypeStatus background="violet50" color="violet900">
            <MdOutlineEngineering />
          </ActivityTypeStatus>
        ),
        primaryText: text,
        secondaryText: (
          <>
            {additional?.description && (
              <Flex>{additional.description.pt}</Flex>
            )}
            {additional?.message && (
              <Flex>{t('event-message-service-order')}</Flex>
            )}
          </>
        ),
        additionalText: (
          <>
            {additional?.message && (
              <Flex justifyContent="space-between">
                <TreatmentText>{additional?.message}</TreatmentText>
              </Flex>
            )}
          </>
        ),
        action: null,
      };
    }
    case activityTypes.TechnicalReview: {
      return {
        iconStatus: (
          <ActivityTypeStatus background="violet50" color="violet900">
            <FiTool />
          </ActivityTypeStatus>
        ),
        primaryText: text,
        secondaryText: (
          <>
            {additional?.description && (
              <Flex>{additional.description.pt}</Flex>
            )}
            {additional?.message && (
              <Flex>{t('event-message-technical-review')}</Flex>
            )}
          </>
        ),
        additionalText: (
          <>
            {additional?.message && (
              <Flex justifyContent="space-between">
                <TreatmentText>{additional?.message}</TreatmentText>
              </Flex>
            )}
          </>
        ),
        action: null,
      };
    }

    case activityTypes.TreatmentStart:
      return {
        iconStatus: (
          <ActivityTypeStatus background="primary50" color="primary900">
            <FiPlay />
          </ActivityTypeStatus>
        ),
        primaryText: text,
        secondaryText: t('treatment-start'),
        action: null,
      };

    case activityTypes.TreatmentStop:
      return {
        iconStatus: (
          <ActivityTypeStatus background="warning50" color="warning900">
            <FiCornerUpLeft />
          </ActivityTypeStatus>
        ),
        primaryText: text,
        secondaryText: t('treatment-stop'),
        action: null,
      };

    case activityTypes.TreatmentEnd:
      return {
        iconStatus: (
          <ActivityTypeStatus
            background={
              untreatedEvent || closureReason ? 'warning50' : 'primary50'
            }
            color={
              untreatedEvent || closureReason ? 'warning900' : 'primary900'
            }
          >
            <FiStopCircle />
          </ActivityTypeStatus>
        ),
        primaryText: text,
        secondaryText: t('treatment-end'),
        additionalText: (
          <Flex justifyContent="space-between">
            <TreatmentText>
              {closureReason &&
                t(`closure-reason.${closureReasonTranslateKey[closureReason]}`)}
              {!closureReason && untreatedEvent && (
                <strong>{untreatedEvent.description}</strong>
              )}
              {!closureReason && !untreatedEvent && additional?.treatment}
            </TreatmentText>
          </Flex>
        ),
        action: null,
      };

    case activityTypes.AnsweredCall:
      return {
        iconStatus: (
          <ActivityTypeStatus background="purple50" color="purple900">
            <FiPhoneIncoming />
          </ActivityTypeStatus>
        ),
        primaryText: text,
        secondaryText: t('answered-call', {
          extension: JSON.parse(additionalInformation)?.extension,
        }),
        action: (
          <Flex>
            <KiperButton
              onClick={() =>
                window.open(
                  `${event.kiperVoiceServer.url}${
                    JSON.parse(additionalInformation)?.callId
                  }`,
                  '_blank',
                )
              }
              icon={<FiPlay />}
              variant="out"
            >
              {t('listen')}
            </KiperButton>
          </Flex>
        ),
      };

    case activityTypes.OutgoingCall:
      return {
        iconStatus: (
          <ActivityTypeStatus background="purple50" color="purple900">
            <FiPhoneOutgoing />
          </ActivityTypeStatus>
        ),
        primaryText: text,
        secondaryText: t('outgoing-call', {
          number: JSON.parse(additionalInformation)?.callTo,
          name:
            additional?.personName ||
            (additional?.destinationKind === destinationTypes.intercomAta
              ? t('intercom')
              : null),
        }),
        action: (
          <Flex>
            <KiperButton
              onClick={() =>
                window.open(
                  `${event.kiperVoiceServer.url}${
                    JSON.parse(additionalInformation)?.callId
                  }`,
                  '_blank',
                )
              }
              icon={<FiPlay />}
              variant="out"
            >
              {t('listen')}
            </KiperButton>
          </Flex>
        ),
      };

    case activityTypes.DoorCommand:
      return {
        iconStatus: (
          <ActivityTypeStatus>
            <RiDoorClosedLine />
          </ActivityTypeStatus>
        ),
        primaryText: text,
        secondaryText: `${t(
          commandTypes[JSON.parse(additionalInformation)?.doorCommand],
        )} ${JSON.parse(additionalInformation)?.placeName}`,
        optionalText:
          !!JSON.parse(additionalInformation)?.reason &&
          `${t('keep-open-reason')} ${
            JSON.parse(additionalInformation)?.reason
          }`,
        action: null,
      };

    case activityTypes.ShowPlayback:
      return {
        iconStatus: (
          <ActivityTypeStatus>
            <FiVideo />
          </ActivityTypeStatus>
        ),
        primaryText: text,
        secondaryText: t('recording-camera'),
        action: (
          <KiperButton
            onClick={() => fetchPlayback()}
            icon={<FiVideo />}
            variant="out"
            loading={loadingShowPlayback}
            disabled={loadingShowPlayback}
          >
            {t('attend')}
          </KiperButton>
        ),
      };

    case activityTypes.PutOnHold:
      return {
        iconStatus: (
          <ActivityTypeStatus background="orange50" color="orange900">
            <FiClock />
          </ActivityTypeStatus>
        ),
        primaryText: text,
        secondaryText: t('event-on-hold', {
          time: formatTimerLabel(JSON.parse(additionalInformation)?.waitTimer),
          reason: JSON.parse(additionalInformation)?.reason,
        }),
      };

    case activityTypes.PutBackOnHold:
      return {
        iconStatus: (
          <ActivityTypeStatus background="orange50" color="orange900">
            <FiClock />
          </ActivityTypeStatus>
        ),
        primaryText: text,
        secondaryText: t('event-returned-to-queue'),
        action: null,
      };

    case activityTypes.ExitGuidedAttendance:
      return {
        iconStatus: (
          <ActivityTypeStatus background="danger100" color="black">
            <FiMessageSquare />
          </ActivityTypeStatus>
        ),
        primaryText: text,
        secondaryText: t('exit-guided-attendance'),
        additionalText: (
          <Flex justifyContent="space-between">
            <TreatmentText>{additional?.reason}</TreatmentText>
          </Flex>
        ),
      };

    case activityTypes.CallStatus:
      return {
        iconStatus: (
          <ActivityTypeStatus>
            <MdOutlinePermPhoneMsg />
          </ActivityTypeStatus>
        ),
        primaryText: text,
        secondaryText: t('call-status'),
        additionalText: (
          <Flex justifyContent="space-between">
            <TreatmentText>
              {translateCallStatus({ status: additional?.status, t })}
            </TreatmentText>
          </Flex>
        ),
      };

    case activityTypes.StepHistory:
      return {
        iconStatus: (
          <ActivityTypeStatus>
            <MdUpdate />
          </ActivityTypeStatus>
        ),
        primaryText: text,
        secondaryText: translateStepHistory({
          step: additional?.componentName,
          params: additional,
          t,
        }),
      };

    case activityTypes.RelayCommand:
      return {
        iconStatus: (
          <ActivityTypeStatus>
            <FiCpu />
          </ActivityTypeStatus>
        ),
        primaryText: text,
        secondaryText: t(`relay.command.description`, {
          command: t(`relay.command.${commandTypes[additional?.relayCommand]}`),
          deviceName: additional?.deviceName,
          relayDescription: additional?.descriptionRelay,
        }),
      };

    case activityTypes.RiskSituationDetected:
      return {
        iconStatus: (
          <ActivityTypeStatus>
            <>
              {additional?.intrusionStatus ===
                riskSituationDetectedStatus.AlarmAcknowledgmentIssueFound && (
                <MdIcons mdIconName="report" />
              )}
              {additional?.intrusionStatus ===
                riskSituationDetectedStatus.AlarmAcknowledgmentNoIssueFound && (
                <MdIcons mdIconName="report_off" />
              )}
            </>
          </ActivityTypeStatus>
        ),
        primaryText: text,
        secondaryText: t(
          `closure-reason.${
            closureReasonTranslateKey[additional?.intrusionStatus]
          }`,
        ),
      };

    case activityTypes.AttendantRuleReview:
      return {
        iconStatus: (
          <ActivityTypeStatus background="danger100" color="danger500">
            <MdOutlineErrorOutline />
          </ActivityTypeStatus>
        ),
        primaryText: text,
        secondaryText:
          additional?.questionAnswerType === questionAnswerType.TEXT
            ? t('review-request.procedure-review', {
                triageName: additional?.triageName,
                subtriageName: additional?.subtriageName,
              })
            : t('review-request.rule-review', {
                triageName: additional?.triageName,
                subtriageName: additional?.subtriageName,
                ruleQuestion: additional?.ruleQuestion,
              }),
      };

    default:
      return {
        iconStatus: (
          <ActivityTypeStatus>
            <FiSquare />
          </ActivityTypeStatus>
        ),
        primaryText: text,
        secondaryText: t('unknown-activity'),
      };
  }
};
