import styled, { css } from 'styled-components';

export const Video = styled.video`
  width: auto;
  ${props =>
    props.maxwidth &&
    css`
      max-width: '${props.maxwidth}px';
    `}
`;
