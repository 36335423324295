import { useEffect, useMemo } from 'react';
import propTypes from 'prop-types';
import { useLazyQuery } from 'react-apollo';

import { Select } from '@kiper/ui';
import { condominiumPlaceCamerasV2 } from '@kiper/monitoring-graphql/condominium/detail/query';

const PlaceCameraSelect = ({
  placeId,
  invalid,
  value,
  disabledValues,
  isDisabled,
  ...props
}) => {
  const [fetch, { data, loading }] = useLazyQuery(condominiumPlaceCamerasV2, {
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (placeId) fetch({ variables: { placeId } });
  }, [placeId]);

  const cameras = useMemo(() => {
    if (!data) return [];
    return data?.condominiumPlaceCamerasV2
      ?.filter(camera => camera.isSelected)
      .map(camera => ({
        ...camera,
        isDisabled: disabledValues.some(
          disabledCameraId => disabledCameraId === camera.id,
        ),
      }));
  }, [data, disabledValues]);

  const getValue = cameraId =>
    cameras?.filter(camera => camera.id === cameraId);

  return (
    <Select
      {...props}
      width="100%"
      isDisabled={isDisabled || loading}
      isLoading={loading}
      options={cameras}
      invalid={Number(invalid)}
      value={getValue(value?.id)}
      getOptionValue={option => option.id}
      getOptionLabel={option => option.name}
    />
  );
};

export default PlaceCameraSelect;

PlaceCameraSelect.propTypes = {
  placeId: propTypes.number,
  invalid: propTypes.bool,
  name: propTypes.string.isRequired,
  value: propTypes.any,
  disabledValues: propTypes.array,
  isDisabled: propTypes.bool,
};

PlaceCameraSelect.defaultProps = {
  placeId: undefined,
  isDisabled: false,
  invalid: false,
  value: '',
  disabledValues: [],
};
