import { useLayoutEffect } from 'react';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Flex, Text } from '@kiper/ui';
import { useGetCameraLive, useGetEventCameras } from '../../hooks';
import CamerasList from '../../../../VideoCamerasGrid/CamerasList';
import LoadingWrapper from './LoadingWrapper';
import CameraOfflineWrapper from './CameraOfflineWrapper';
import { VideoImg } from './styles';

const PlaceCamera = ({ event }) => {
  const { t } = useTranslation('guided-attendance');

  const {
    placeCameras,
    selectedCamera,
    handleSelectCamera,
    loadingCameras,
  } = useGetEventCameras({ eventId: event?.eventId });

  const {
    loadingCameraLive,
    loadingImg,
    cameraOffline,
    handleImageLoaded,
    handleCameraError,
    camRef,
  } = useGetCameraLive({
    selectedCamera,
    condominiumPersonContextId: event?.condominium?.personContextId,
  });

  useLayoutEffect(() => {
    return () => {
      if (camRef?.current) camRef.current.src = '';
    };
  }, []);

  return (
    <Flex flexDirection="column" gridGap="4px">
      <Text>{t('step-open-doors.cameras')}</Text>
      <Flex flexDirection="column" width="100%" gridGap="4px">
        {loadingCameraLive || loadingCameras ? (
          <LoadingWrapper />
        ) : (
          <>
            {loadingImg && <LoadingWrapper />}
            {cameraOffline && <CameraOfflineWrapper />}
            <VideoImg
              ref={camRef}
              alt={selectedCamera && selectedCamera.name}
              id={selectedCamera?.id}
              onError={handleCameraError}
              onLoad={handleImageLoaded}
              loading={Number(loadingImg)}
              offline={Number(cameraOffline)}
            />
          </>
        )}
      </Flex>
      <CamerasList
        cameras={placeCameras}
        handleClickThumbnail={handleSelectCamera}
        event={event}
      />
    </Flex>
  );
};

export default PlaceCamera;

PlaceCamera.propTypes = {
  event: propTypes.object.isRequired,
};
