import {
  EnableAttendant,
  EnableCondominium,
  EnablePartner,
} from './components';
import GuidedAttendanceSettings from './GuidedAttendanceSettings';

const breadcrumb = (label, to) => ({ label, to });

export default [
  {
    name: 'Guided attendance settings',
    path: '/guided-attendance-settings',
    exact: true,
    component: GuidedAttendanceSettings,
    breadcrumb: [
      breadcrumb(
        'common:breadcrumb.guided-attendance-settings',
        '/guided-attendance-settings',
      ),
    ],
  },
  {
    name: 'Guided attendance settings enable condominium',
    path: '/guided-attendance-settings/enable-condominium',
    exact: true,
    component: EnableCondominium,
    breadcrumb: [
      breadcrumb(
        'common:breadcrumb.guided-attendance-settings',
        '/guided-attendance-settings',
      ),
      breadcrumb(
        'common:breadcrumb.enable-condominium',
        '/guided-attendance-settings/enable-condominium',
      ),
    ],
  },
  {
    name: 'Guided attendance settings enable attendant',
    path: '/guided-attendance-settings/enable-attendant',
    exact: true,
    component: EnableAttendant,
    breadcrumb: [
      breadcrumb(
        'common:breadcrumb.guided-attendance-settings',
        '/guided-attendance-settings',
      ),
      breadcrumb(
        'common:breadcrumb.enable-attendant',
        '/guided-attendance-settings/enable-attendant',
      ),
    ],
  },
  {
    name: 'Guided attendance settings enable partner',
    path: '/guided-attendance-settings/enable-partner',
    exact: true,
    component: EnablePartner,
    breadcrumb: [
      breadcrumb(
        'common:breadcrumb.guided-attendance-settings',
        '/guided-attendance-settings',
      ),
      breadcrumb(
        'common:breadcrumb.enable-partner',
        '/guided-attendance-settings/enable-partner',
      ),
    ],
  },
];
