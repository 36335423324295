import { useEffect, useMemo, useContext } from 'react';
import propTypes from 'prop-types';
import { useWindowDimensions } from '@kiper/hooks';
import { useTranslation } from 'react-i18next';
import { Flex, ScrollBar, TabContent, TabPane } from '@kiper/ui';
import { useDevices, useDeviceStatus } from '../../../../hooks';
import EventPlaces from '../EventPlaces';
import EventOnHold from '../EventOnHold';
import CondoMap from '../CondoMap';
import { Cameras } from '../Cameras';
import VehicularControlPanel from '../../../../components/VehicularControlPanel';
import { Users } from '../../../../components/Lists';
import { TriageContext } from '../../../../store';
import * as S from './styles';

const PERSON_CONTEXT_PARAMS_NAME = {
  URL_MAP: 'urlMap',
};

const EventDetailsBodyV2 = ({
  event,
  formik,
  eventCameras,
  isInAttendance,
  activeTab,
  toggleTab,
}) => {
  const { triageContext } = useContext(TriageContext);
  const { filterGUUnit, triage } = triageContext;
  const { width } = useWindowDimensions();
  const [t] = useTranslation('event_attendance');

  const {
    cameras,
    mainCamera,
    places,
    devices,
    interlocks,
    selectedPlace,
    setSelectedPlace,
    isDeviceLoading,
    isDeviceOpened,
    isDeviceKeepOpened,
    handleDevice,
  } = useDevices({
    event,
    newCameras: eventCameras,
    triageId: triage?.id,
  });

  const { deviceStatus } = useDeviceStatus({ places });

  const { urlMap, unityTreeNodeId } = useMemo(
    () => ({
      urlMap: event?.condominium?.personContext?.params?.find(
        param => param.name === PERSON_CONTEXT_PARAMS_NAME.URL_MAP,
      )?.value,
      unityTreeNodeId: event?.additionalInformation?.unityTreeNodeId,
    }),
    [event],
  );

  useEffect(() => {
    const isSinglePlace =
      event?.condominium?.personContext?.places?.length === 1;

    if (isSinglePlace)
      setSelectedPlace(event.condominium.personContext.places[0].id);
  }, [event]);

  return (
    <Flex
      overflow="auto"
      bg="white"
      height="100%"
      borderRadius="4px"
      flexDirection="column"
    >
      <ScrollBar>
        <S.NavContainer>
          <S.Nav tabs>
            <S.NavItem>
              <S.NavLink
                active={activeTab === 'commands'}
                onClick={() => toggleTab('commands')}
                data-cy="commands"
              >
                <S.CommandsIcon active={+(activeTab !== 'commands')} />
                <S.Text active={+(activeTab !== 'commands')}>
                  {t('details.body.menu-tab.commands')}
                </S.Text>
              </S.NavLink>
              <S.NavLink
                active={activeTab === 'dwellers'}
                onClick={() => toggleTab('dwellers')}
                data-cy="dwellers"
              >
                <S.DwellersIcon active={+(activeTab !== 'dwellers')} />
                <S.Text active={+(activeTab !== 'dwellers')}>
                  {t('details.body.menu-tab.dwellers-and-pre-authorized')}
                </S.Text>
              </S.NavLink>
            </S.NavItem>
          </S.Nav>
        </S.NavContainer>
        <TabContent activeTab={activeTab}>
          {activeTab === 'commands' && (
            <TabPane tabId="commands">
              <Flex flexDirection="column" flex="1 1 0" minHeight="0">
                <EventPlaces
                  places={places}
                  selectedPlace={selectedPlace}
                  setSelectedPlace={setSelectedPlace}
                />
                <ScrollBar>
                  {event?.onHold && <EventOnHold event={event} />}
                  <Flex>
                    {width >= 1920 && urlMap && (
                      <>
                        <CondoMap
                          urlMap={urlMap}
                          places={places}
                          selectedPlace={selectedPlace}
                          setSelectedPlace={setSelectedPlace}
                          event={event}
                          deviceStatus={deviceStatus}
                        />
                      </>
                    )}
                    <Cameras
                      t={t}
                      event={event}
                      cameras={cameras}
                      mainCamera={mainCamera}
                      places={places}
                      devices={devices}
                      interlocks={interlocks}
                      selectedPlace={selectedPlace}
                      setSelectedPlace={setSelectedPlace}
                      handleDevice={handleDevice}
                      isDeviceLoading={isDeviceLoading}
                      isDeviceOpened={isDeviceOpened}
                      isDeviceKeepOpened={isDeviceKeepOpened}
                      disabledActions={!isInAttendance}
                      screenWidth={width}
                      deviceStatus={deviceStatus}
                    />
                  </Flex>
                  {event.eventType === 176 && (
                    <Flex py="10px" flexWrap="wrap">
                      {(event?.personDevices ?? []).map(({ id }) => (
                        <S.Col key={id} md={3}>
                          <S.DeviceContainer>
                            <VehicularControlPanel
                              deviceId={id}
                              transparent
                              disabled={!isInAttendance}
                            />
                          </S.DeviceContainer>
                        </S.Col>
                      ))}
                    </Flex>
                  )}
                </ScrollBar>
              </Flex>
            </TabPane>
          )}
          {activeTab === 'dwellers' && (
            <TabPane tabId="dwellers">
              <Flex
                flexDirection="column"
                px="16px"
                py="10px"
                flex="1 1 0"
                minHeight="0"
              >
                <Users
                  formik={formik}
                  event={event}
                  guUnFilter={unityTreeNodeId || filterGUUnit}
                />
              </Flex>
            </TabPane>
          )}
        </TabContent>
      </ScrollBar>
    </Flex>
  );
};

export default EventDetailsBodyV2;

EventDetailsBodyV2.propTypes = {
  event: propTypes.object.isRequired,
  isInAttendance: propTypes.bool.isRequired,
  formik: propTypes.object.isRequired,
  activeTab: propTypes.string.isRequired,
  toggleTab: propTypes.func.isRequired,
  eventCameras: propTypes.array,
};

EventDetailsBodyV2.defaultProps = {
  eventCameras: [],
};
