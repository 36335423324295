import { useState } from 'react';
import propTypes from 'prop-types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { MdChevronRight } from 'react-icons/md';
import { Badge, Flex, KiperButton, Text, Tooltip } from '@kiper/ui';
import { formatDate, phoneMask } from '@kiper/fns';
import { profileTypes, profileColor } from '../../../../constants';
import useCurrentLoggedContext from '../../../../hooks/useCurrentLoggedContext';
import { sendAmplitudeData } from '../../../../services/amplitude';
import { Tr } from '../styles';
import { Actions, Td } from './styles';

function UsersGuestsRow({
  trRef,
  item,
  event,
  formik,
  handleSearchInput,
  toggleTab,
}) {
  const { t } = useTranslation('event_attendance');

  const { loggedContext } = useCurrentLoggedContext();

  const guestDocuments = item?.guestParams?.map(document =>
    JSON.parse(document),
  );

  const guestStatus = {
    fifteenMinutes: t('user:list.guest-status.fifteenMinutes'),
    thirtyMinutes: t('user:list.guest-status.thirtyMinutes'),
    hour: t('user:list.guest-status.hour'),
    active: t('user:list.guest-status.authorized'),
  };

  const [hovered, setHover] = useState(false);

  const authorizedOrGuest = item?.guestPhones?.[0]
    ? t(`common:profiles.${profileTypes.guest}`)
    : t(`common:profiles.${profileTypes.authorized}`);

  const handleGoToHost = () => {
    handleSearchInput('searchText', item?.hostName);
    toggleTab('users');
  };

  return (
    <Tr
      ref={trRef}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      className="voice-list-user"
    >
      <td align="left">
        <Flex flexDirection="column">
          <Text mb="5px" fontSize="14px">
            {item?.unityName || ''}
          </Text>
          <Text fontSize="12px" color="secondary400">
            {item?.unityGroupName || ''}
          </Text>
        </Flex>
      </td>
      <Td align="left" onClick={handleGoToHost}>
        <Flex flexDirection="column">
          <Text className="hostname" mb="5px" fontSize="14px">
            {item?.hostName}
          </Text>
          <Flex>
            <Flex mr="4px">
              <Badge color={profileColor.resident}>
                {t(`common:profiles.${profileTypes.resident}`)}
              </Badge>
            </Flex>
          </Flex>
        </Flex>
      </Td>
      <td align="left">
        <Flex flexDirection="column">
          <Text mb="5px" fontSize="14px">
            {item?.guestName}
          </Text>
          <Flex>
            <Flex mr="4px">
              <Badge color={profileColor.guest}>{authorizedOrGuest}</Badge>
            </Flex>
          </Flex>
        </Flex>
      </td>
      <td>
        <Flex flexDirection="column">
          <Text mb="5px" fontSize="14px">
            {phoneMask(item?.guestPhones?.[0]).formatted}
          </Text>
          <Text fontSize="12px" color="secondary500">
            {guestDocuments?.length
              ? `${guestDocuments?.[0]?.documentType}: ${guestDocuments?.[0]?.document}`
              : '-'}
          </Text>
        </Flex>
      </td>

      <td>
        <Flex flexDirection="column">
          <Text mb="5px" fontSize="14px">
            {formatDate(item?.startDate, 'DD/MM - HH:mm').formatted}
          </Text>
          <Flex>
            <Flex mr="4px">
              <Badge
                color={
                  item?.inviteStatus === 'active' ? 'primary500' : 'warning100'
                }
              >
                {guestStatus[item?.inviteStatus]}
              </Badge>
            </Flex>
          </Flex>
        </Flex>
      </td>
      <td>
        <Flex alignItems="center">
          {formatDate(item?.endDate, 'DD/MM - HH:mm').formatted}
          {hovered && (
            <Actions id={`report-visitor-button-${item?.personContextId}`}>
              <KiperButton
                rounded
                onClick={() => {
                  formik.setFieldValue(
                    'report',
                    formik?.values?.report?.concat(
                      `${t('report-visitor', {
                        date: formatDate(moment(), 'DD/MM - HH:mm').formatted,
                        guest: item?.guestName,
                        unity: item?.unityName,
                        host: item?.hostName,
                      })}\n`,
                    ),
                  );
                  sendAmplitudeData('register invite log', {
                    user: loggedContext?.email,
                    partner: loggedContext?.partner?.name,
                    condominium: event?.condominium?.name,
                    guest: item?.guestName,
                    unit: item?.unityName,
                    host: item?.hostName,
                  });
                }}
                variant="out"
                icon={<MdChevronRight />}
              />
              <Tooltip
                placement="bottom"
                target={`report-visitor-button-${item?.personContextId}`}
              >
                {t('report-visitor-log')}
              </Tooltip>
            </Actions>
          )}
        </Flex>
      </td>
    </Tr>
  );
}

export default UsersGuestsRow;

UsersGuestsRow.propTypes = {
  item: propTypes.object.isRequired,
  event: propTypes.object,
  formik: propTypes.shape({
    values: propTypes.object,
    setFieldValue: propTypes.func,
  }),
  trRef: propTypes.any,
  handleSearchInput: propTypes.func.isRequired,
  toggleTab: propTypes.func.isRequired,
};

UsersGuestsRow.defaultProps = {
  event: null,
  formik: null,
  trRef: null,
};
