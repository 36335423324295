import { useContext, useEffect, useMemo } from 'react';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { SplitSidebar } from '@kiper/ui';
import { useToggle } from '@kiper/hooks';
import { MdFilterList } from 'react-icons/md';
import { CondominiumRuleFilters, QuestionsAndAnswers } from './components';
import {
  CondominiumRuleFilterContext,
  GuidedAttendanceContext,
} from '../../../store';
import { sendAmplitudeData } from '../../../services/amplitude';
import * as S from './styles';

const translatePrefix = (t, key) =>
  t(`quick-menu.condominium-rule-questions-awswers.${key}`);

const CondominiumRuleQuestionsAnswers = ({
  event,
  condominiumPersonContextId,
}) => {
  const { t } = useTranslation('guided-attendance');
  const { contextTree } = useContext(GuidedAttendanceContext);
  const { setFilterContext } = useContext(CondominiumRuleFilterContext);
  const [showFilters, toggleShowFilters] = useToggle(false);

  const stepNamesToIgnore = [
    'StepGetSubtriage',
    'StepAttendanceGetReason',
    'StepAttendanceGetName',
  ];

  const { triage, subtriage } = useMemo(
    () => ({
      subtriage:
        !stepNamesToIgnore.includes(contextTree?.currentStep?.componentName) &&
        contextTree?.data?.stepGetSubtriage,
      triage: contextTree?.data?.stepAttendanceGetReason,
    }),
    [contextTree?.data, contextTree.currentStep],
  );

  const handleToggleShowFilters = () => {
    const amplitudeKey = showFilters
      ? 'condominium rules filter: close'
      : 'condominium rules filter: open';
    toggleShowFilters();
    sendAmplitudeData(amplitudeKey);
  };

  useEffect(() => {
    if (triage && subtriage) {
      setFilterContext({
        selectedTriage: triage,
        filteredTriage: triage,
        selectedSubtriage: subtriage,
        filteredSubtriage: subtriage,
      });
    }
  }, [triage, subtriage]);

  return (
    <S.Wrapper>
      <SplitSidebar.HeaderPane justifyContent="space-between" hasBorder>
        {translatePrefix(t, 'title')}
        <S.Button
          variant="text"
          filters={showFilters}
          icon={<MdFilterList />}
          onClick={handleToggleShowFilters}
        />
      </SplitSidebar.HeaderPane>

      {showFilters && (
        <CondominiumRuleFilters
          condominiumPersonContextId={condominiumPersonContextId}
        />
      )}

      <QuestionsAndAnswers
        condominiumPersonContextId={condominiumPersonContextId}
        event={event}
        showFilters={showFilters}
        t={key => translatePrefix(t, key)}
      />
    </S.Wrapper>
  );
};

export default CondominiumRuleQuestionsAnswers;

CondominiumRuleQuestionsAnswers.propTypes = {
  condominiumPersonContextId: propTypes.number.isRequired,
  event: propTypes.object.isRequired,
};
