import { useState } from 'react';
import propTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import { Flex, MdIcons, Text } from '@kiper/ui';
import { useGuidedAttendance } from '../../../hooks';
import { useForm } from './hooks';
import StepStructure, { StepTextTypeColor } from '../StepStructure';
import { FaceCaptureStream } from './components';

const StepPhotoRegister = ({ event, history, eventId }) => {
  const { t } = useTranslation('guided-attendance');
  const [photo, setPhoto] = useState(null);
  const { handleGetTriageSelected } = useGuidedAttendance();
  const { values } = useForm(eventId);

  const handleFallback = () => {
    history.push(`/guided-attendance/${eventId}/fallback`);
  };

  return (
    <StepStructure
      type={StepTextTypeColor.TAKE_PHOTO}
      text={
        photo
          ? t('step-photo-register.preview-photo-title')
          : t('step-photo-register.title', {
              name: values?.stepAttendanceGetName?.name,
            })
      }
      hasPreviousStepButton
    >
      {!photo && (
        <Flex flexDirection="column" width="100%">
          <Text>
            <Trans>
              {t('step-photo-register.photo-of', {
                triage: t(
                  `step-photo-register.triage.${handleGetTriageSelected(
                    values,
                  )}`,
                ),
              })}
            </Trans>
          </Text>
          <Flex gridGap="4px" alignItems="center">
            <MdIcons mdIconName="error" size="20px" color="orange500" />
            <Text fontSize="12px" color="orange500">
              <Trans>{t('step-photo-register.info')}</Trans>
            </Text>
          </Flex>
        </Flex>
      )}
      <FaceCaptureStream
        event={event}
        handleFallback={handleFallback}
        photo={photo}
        setPhoto={setPhoto}
      />
    </StepStructure>
  );
};

export default StepPhotoRegister;

StepPhotoRegister.propTypes = {
  event: propTypes.object.isRequired,
  history: propTypes.object,
  eventId: propTypes.string.isRequired,
};

StepPhotoRegister.defaultProps = {
  history: {},
};
