import { shape, string, number, oneOf, func } from 'prop-types';
import { formatDistanceDate } from '@kiper/fns';
import { useToggle } from '@kiper/hooks';
import { FiNavigation } from 'react-icons/fi';
import { Button, Flex, Text } from '@kiper/ui';
import { continuousAttendanceStatus } from '../../../../hooks/useContinuousAttendance';
import { EventActivityModal } from '../../../Modals';
import * as S from './styles';

const PREFIX_TRANSLATE = 'details.continuous-attendance';

const ContinuousAttendanceDetail = ({ attendance, t }) => {
  const [showActivityModal, toggleActivityModal] = useToggle(false);
  const handleTreatValue = value => {
    return value || t(`${PREFIX_TRANSLATE}.uninformed`);
  };

  const handleFormatUnityText = () => {
    const { unity, unityGroup } = attendance;
    let result = '';

    if (unityGroup?.name) result = unityGroup.name;
    if (unity?.name) result += ` ${unity.name}`;

    return handleTreatValue(result);
  };

  const treatedStatusObject = Object.freeze({
    OnGoing: {
      text: t(`${PREFIX_TRANSLATE}.attendance-ongoing-tooltip`),
      color: 'violet900',
    },
    Authorized: {
      text: t(`${PREFIX_TRANSLATE}.attendance-authorized-tooltip`),
      color: 'primary500',
    },
    NotAuthorized: {
      text: t(`${PREFIX_TRANSLATE}.attendance-unauthorized-tooltip`),
      color: 'danger500',
    },
    UnavailableContacts: {
      text: t(`${PREFIX_TRANSLATE}.attendance-unavailable-contacts-tooltip`),
      color: 'warning500',
    },
    AtTheConcierge: {
      text: t(`${PREFIX_TRANSLATE}.attendance-at-the-concierge`),
      color: 'info300',
    },
  });

  return (
    <Flex width="100%" height="100%" flexDirection="column" p="8px">
      <Flex mb="8px">
        <Flex flexDirection="column" width="50%" flex="0.5">
          <S.SecondaryText>{attendance?.triageName}</S.SecondaryText>
          <S.TextEllipsis title={attendance?.identifier}>
            {handleTreatValue(attendance?.identifier)}
          </S.TextEllipsis>
        </Flex>
        <Flex flexDirection="column" width="50%" flex="0.5">
          <S.SecondaryText>
            {t(`${PREFIX_TRANSLATE}.detail.unity`)}
          </S.SecondaryText>
          <S.TextEllipsis title={handleFormatUnityText()}>
            {handleFormatUnityText()}
          </S.TextEllipsis>
        </Flex>
      </Flex>

      <Flex mb="8px">
        <Flex flexDirection="column" width="50%" flex="0.5">
          <S.SecondaryText>
            {t(`${PREFIX_TRANSLATE}.detail.occurred-in`)}
          </S.SecondaryText>
          <S.TextEllipsis
            title={formatDistanceDate(attendance.creationDate, t)}
          >
            {formatDistanceDate(attendance.creationDate, t)}
          </S.TextEllipsis>
        </Flex>
        <Flex flexDirection="column" width="50%" flex="0.5">
          <S.SecondaryText>
            {t(`${PREFIX_TRANSLATE}.detail.serviced-by`)}
          </S.SecondaryText>
          <S.TextEllipsis title={attendance?.attendant?.name}>
            {handleTreatValue(attendance?.attendant?.name)}
          </S.TextEllipsis>
        </Flex>
      </Flex>

      <Flex mb="8px">
        <Flex flexDirection="column" width="50%" flex="0.5">
          <S.SecondaryText>
            {t(`${PREFIX_TRANSLATE}.detail.access`)}
          </S.SecondaryText>
          <S.TextEllipsis title={attendance?.placeName}>
            {handleTreatValue(attendance?.placeName)}
          </S.TextEllipsis>
        </Flex>
        <Flex flexDirection="column" width="50%" flex="0.5">
          <S.SecondaryText>
            {t(`${PREFIX_TRANSLATE}.detail.status`)}
          </S.SecondaryText>
          <S.TextEllipsis
            color={treatedStatusObject[attendance.status].color}
            title={treatedStatusObject[attendance.status].text}
          >
            {treatedStatusObject[attendance.status].text}
          </S.TextEllipsis>
        </Flex>
      </Flex>

      <Flex width="100%">
        <Flex flexDirection="column" width="100%">
          <Flex width="100%" alignItems="center">
            <Flex width="50%" flex="0.5">
              <S.SecondaryText>
                {t(`${PREFIX_TRANSLATE}.detail.event-registration`)}
              </S.SecondaryText>
            </Flex>
            <Flex width="50%" flex="0.5">
              <Button
                variant="out"
                color="neutral"
                size="sm"
                title={t(`${PREFIX_TRANSLATE}.activity-history`)}
                icon={<FiNavigation />}
                onClick={toggleActivityModal}
              >
                {t(`${PREFIX_TRANSLATE}.activities`)}
              </Button>
            </Flex>
          </Flex>
          <Text fontWeight="400" fontSize="14px" lineHeight="20px">
            {attendance?.treatment}
          </Text>
        </Flex>
      </Flex>
      {showActivityModal && (
        <EventActivityModal
          eventId={attendance?.eventId}
          eventDate={attendance?.creationDate}
          onToggle={toggleActivityModal}
        />
      )}
    </Flex>
  );
};

export default ContinuousAttendanceDetail;

ContinuousAttendanceDetail.propTypes = {
  attendance: shape({
    creationDate: string.isRequired,
    eventId: string.isRequired,
    dweller: string,
    identifier: string,
    triageName: string,
    unity: shape({
      name: string,
    }),
    unityGroup: shape({
      name: string,
    }),
    status: oneOf([...Object.values(continuousAttendanceStatus)]).isRequired,
    attendant: shape({
      id: number.isRequired,
      name: string.isRequired,
    }).isRequired,
    placeName: string,
    treatment: string,
  }).isRequired,
  t: func.isRequired,
};
