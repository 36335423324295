import { useFormikContext } from 'formik';
import { useState, useRef, useLayoutEffect } from 'react';

const useStreamStatus = ({ setPhoto, refetchLive }) => {
  const imgRef = useRef(new Image());
  const [loadingLive, setLoadingLive] = useState(true);
  const [errorLoadingImg, setErrorLoadingImg] = useState(false);
  const { values, setFieldValue, setFieldError } = useFormikContext();

  const handleLoadImg = () => {
    if (loadingLive) setLoadingLive(false);
  };

  const handleErrorImg = () => {
    if (loadingLive) setLoadingLive(false);
    setErrorLoadingImg(true);
    if (!values?.stepPhotoRegister?.photoRegistered) {
      setFieldValue('stepPhotoRegister', {
        photoRegistered: false,
        cameraUnavailable: true,
      });
    }
  };

  const handleTryAgain = () => {
    setFieldError('stepPhotoRegister.photoRegistered', null);
    setPhoto(null);
    setLoadingLive(true);
    refetchLive();
  };

  useLayoutEffect(() => {
    return () => {
      if (imgRef?.current) {
        imgRef.current.src = '';
      }
    };
  }, []);

  return {
    imgRef,
    loadingLive,
    setLoadingLive,
    errorLoadingImg,
    setErrorLoadingImg,
    handleLoadImg,
    handleErrorImg,
    handleTryAgain,
  };
};

export default useStreamStatus;
