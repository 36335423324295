import { useEffect, useLayoutEffect, forwardRef } from 'react';
import propTypes from 'prop-types';
import Hls from 'hls.js';
import { Flex } from '@kiper/ui';
import { Video } from './styles';

const HLSPlayer = forwardRef(
  ({ url, width, height, handleLoad, handleError }, ref) => {
    useLayoutEffect(() => {
      const refCurrent = ref?.current;

      if (Hls.isSupported()) {
        const hls = new Hls({
          maxBufferLength: 30,
          maxMaxBufferLength: 60,
          capLevelToPlayerSize: true,
          enableWorker: true, // Usa Web Workers para processamento
          lowLatencyMode: true, // Reduz latência
          backBufferLength: 30, // Limita buffer anterior
          progressive: true, // Carregamento progressivo
          startPosition: -1, // Inicia do último segmento
        });

        hls.loadSource(url);
        hls.attachMedia(refCurrent);

        hls.on(Hls.Events.MANIFEST_PARSED, () => {
          const playPromise = refCurrent.play();

          if (playPromise !== undefined) {
            playPromise.catch(error => {
              console.error('Erro ao tentar reproduzir:', error);
            });
          }
        });

        hls.on(Hls.Events.MANIFEST_LOADED, () => {});

        hls.on(Hls.Events.ERROR, (_, data) => {
          if (data.fatal) {
            switch (data.type) {
              case Hls.ErrorTypes.NETWORK_ERROR:
                console.error('HLS Network error:', data);
                handleError();
                hls.startLoad();
                break;
              case Hls.ErrorTypes.MEDIA_ERROR:
                console.error('HLS Media error:', data);
                hls.recoverMediaError();
                break;
              default:
                handleError();
                hls.destroy();
                break;
            }
          }
        });

        return () => {
          hls.destroy();
        };
      }
      if (refCurrent.canPlayType('application/vnd.apple.mpegurl')) {
        refCurrent.src = url;
        refCurrent.setAttribute('crossorigin', 'use-credentials');
        refCurrent.play();
      }

      return () => {
        if (refCurrent) {
          refCurrent.src = '';
          refCurrent.load();
        }
      };
    }, []);

    useEffect(() => {
      if (ref.current) {
        const video = ref.current;
        video.addEventListener('error', handleError);
        video.addEventListener('loadeddata', handleLoad);

        return () => {
          video.removeEventListener('error', handleError);
          video.removeEventListener('loadeddata', handleLoad);
        };
      }

      return () => {};
    }, [ref]);

    return (
      <Flex height={height} width={width} justifyContent="center">
        <Video
          ref={ref}
          maxwidth={width}
          height={height}
          crossOrigin="anonymous"
          muted
        />
      </Flex>
    );
  },
);

HLSPlayer.displayName = 'HLSPlayer';

export default HLSPlayer;

HLSPlayer.propTypes = {
  url: propTypes.string.isRequired,
  width: propTypes.string.isRequired,
  height: propTypes.string.isRequired,
  handleLoad: propTypes.func.isRequired,
  handleError: propTypes.func.isRequired,
};
