import { useMemo, useState, useEffect } from 'react';
import propTypes from 'prop-types';
import { Text, Tooltip } from '@kiper/ui';
import {
  MdOutlinePlace,
  MdOutlineDoorBack,
  MdKeyboardVoice,
} from 'react-icons/md';
import { RiDoorOpenLine } from 'react-icons/ri';
import { firstBy } from 'thenby';
import { useTranslation } from 'react-i18next';
import { PlaceStyled } from './styles';
import { sendAmplitudeData } from '../../../../../../../services/amplitude';
import { deviceIdTypes, doorStatusTypes } from '../../../../../../../constants';

const TooltipPlaceContent = ({ doorStatus, doorKeepOpenedReason, t }) => {
  switch (doorStatus) {
    case doorStatusTypes.KeepOpened:
      return (
        <>
          <Text fontWeight="600" fontSize="14px" lineHeight="20px">
            {t('details.cameras.commands.kept-open-badge-tooltip')}
          </Text>
          <br />
          <Text fontWeight="400" fontSize="14px" lineHeight="20px">
            {doorKeepOpenedReason}
          </Text>
        </>
      );
    default:
      return null;
  }
};

TooltipPlaceContent.propTypes = {
  doorStatus: propTypes.string.isRequired,
  doorKeepOpenedReason: propTypes.string.isRequired,
  t: propTypes.func.isRequired,
};

const MAP_SIZE_BASE = 500;

const Place = ({
  place,
  selectedPlace,
  setSelectedPlace,
  event,
  loggedContext,
  deviceStatus,
  sizeMap,
  ...props
}) => {
  const [t] = useTranslation('event_attendance');

  const [iconStatus, setIconStatus] = useState(<MdOutlineDoorBack />);
  const [doorStatus, setDoorStatus] = useState(null);
  const [doorKeepOpenedReason, setDoorKeepOpenedReason] = useState(null);

  const { device, coordinate, isOrigin } = useMemo(() => {
    const sortedDevice = place?.devices
      ?.filter(
        x =>
          x.deviceTypeId === deviceIdTypes.vehicular ||
          x.deviceTypeId === deviceIdTypes.accessVehicular ||
          x.deviceTypeId === deviceIdTypes.accessVehicularV2 ||
          x.deviceTypeId === deviceIdTypes.kvoice ||
          x.deviceTypeId === deviceIdTypes.interphone ||
          x.deviceTypeId === deviceIdTypes.access ||
          x.deviceTypeId === deviceIdTypes.indoor ||
          x.deviceTypeId === deviceIdTypes.facial ||
          x.deviceTypeId === deviceIdTypes.facialIntelbras,
      )
      .sort(
        firstBy(
          d =>
            !(
              JSON.parse(d?.serializedParams)?.onlyAudio ||
              (!JSON.parse(d?.serializedParams)?.urlToOpen &&
                d.deviceTypeId === deviceIdTypes.interphone)
            ),
          { direction: 'desc' },
        ),
      );

    return {
      device: sortedDevice?.length ? sortedDevice[0] : null,
      coordinate: JSON.parse(place?.serializedParams)?.coordinate,
      isOrigin: event?.placeId === place.id,
    };
  }, []);

  const deviceIsOnlyAudio = useMemo(() => {
    return (
      !!device &&
      (JSON.parse(device?.serializedParams)?.onlyAudio ||
        (!JSON.parse(device?.serializedParams)?.urlToOpen &&
          device?.deviceTypeId === deviceIdTypes.interphone))
    );
  }, [device]);

  useEffect(() => {
    const hasStatus = deviceStatus?.find(
      x =>
        x.serialNumber === device?.serialNumber &&
        x.messageData.doorId === device?.doorId,
    );

    setDoorStatus(hasStatus?.messageData?.doorStatus || device?.doorStatus);
    setDoorKeepOpenedReason(
      hasStatus?.messageData?.keepOpenedReason || device?.keepOpenedReason,
    );
  }, [device, deviceStatus]);

  useEffect(() => {
    let status = doorStatus;
    if (isOrigin) status = 'isOrigin';
    else if (deviceIsOnlyAudio) status = 'isOnlyAudio';

    const iconOptions = {
      isOrigin: <MdOutlinePlace size={14} />,
      isOnlyAudio: <MdKeyboardVoice size={14} />,
      [doorStatusTypes.Closed]: <MdOutlineDoorBack size={14} />,
      [doorStatusTypes.Opened]: <RiDoorOpenLine size={14} />,
      [doorStatusTypes.KeepOpened]: <RiDoorOpenLine size={14} />,
    };

    if (status) {
      setIconStatus(iconOptions[status]);
    }
  }, [isOrigin, doorStatus, deviceIsOnlyAudio]);

  const handleClick = () => {
    setSelectedPlace(place.id);

    sendAmplitudeData('changed place by map', {
      eventId: event?.eventId,
      place: place?.name,
      condominium: event?.condominium?.name,
      user: loggedContext?.personContextId,
    });
  };

  const newCoordinates = useMemo(() => {
    if (!coordinate) return null;
    return {
      y: (coordinate.y / MAP_SIZE_BASE) * sizeMap,
      x: (coordinate.x / MAP_SIZE_BASE) * sizeMap,
      ratio: sizeMap / MAP_SIZE_BASE,
    };
  }, [coordinate, sizeMap]);

  if (!coordinate) return null;

  return (
    <>
      <PlaceStyled
        id={`map-place-${place.id}`}
        onClick={handleClick}
        origin={Number(event?.placeId === place.id)}
        coordinate={newCoordinates}
        selected={selectedPlace === place.id}
        doorStatus={doorStatus}
        {...props}
      >
        {newCoordinates && iconStatus}
      </PlaceStyled>
      {doorStatus === doorStatusTypes.KeepOpened && doorKeepOpenedReason && (
        <Tooltip placement="top" target={`map-place-${place.id}`}>
          <TooltipPlaceContent
            t={t}
            doorStatus={doorStatus}
            doorKeepOpenedReason={doorKeepOpenedReason}
          />
        </Tooltip>
      )}
    </>
  );
};

export default Place;

Place.propTypes = {
  place: propTypes.object.isRequired,
  selectedPlace: propTypes.number,
  setSelectedPlace: propTypes.func.isRequired,
  event: propTypes.object.isRequired,
  loggedContext: propTypes.object.isRequired,
  deviceStatus: propTypes.array.isRequired,
  sizeMap: propTypes.number.isRequired,
};

Place.defaultProps = {
  selectedPlace: null,
};
