import { useEffect, useState, createContext, useMemo } from 'react';
import propTypes from 'prop-types';
import { useLazyQuery } from 'react-apollo';
import { getPlacesToOpenDoor } from '@kiper/monitoring-graphql/guided_attendance';
import { useFormikContext } from 'formik';

export const PlacesContext = createContext();
export const PlacesProvider = ({ children, event }) => {
  const { values } = useFormikContext();
  const [places, setPlaces] = useState([]);
  const [selectedPlace, setSelectedPlace] = useState(null);

  const handleSelectedPlace = place => {
    if (selectedPlace?.id !== place?.id) {
      setSelectedPlace(place);
    }
  };

  const [getPlaces, { loading }] = useLazyQuery(getPlacesToOpenDoor, {
    fetchPolicy: 'no-cache',
    onCompleted: ({ placesToOpenDoor }) => {
      const placesList = placesToOpenDoor?.places || [];
      setPlaces(placesList);

      if (placesList.length === 0) return;

      if (event?.placeId) {
        const matchedPlace = placesList.find(
          place => place.id === event.placeId,
        );
        handleSelectedPlace(matchedPlace || placesList[0]);
      } else {
        handleSelectedPlace(placesList[0]);
      }
    },
  });

  useEffect(() => {
    const unityId = values?.stepAttendanceGetUnity?.unity?.id;
    const condominiumPersonContextId = event?.condominium?.personContextId;
    const triageId = values?.stepAttendanceGetReason?.id;

    if (condominiumPersonContextId || unityId) {
      getPlaces({
        variables: {
          condominiumPersonContextId,
          unityId,
          placeId: null,
          triageId,
        },
      });
    }
  }, [values?.stepAttendanceGetUnity, event]);

  const contextValues = useMemo(
    () => ({
      places,
      selectedPlace,
      handleSelectedPlace,
      loading,
    }),
    [places, selectedPlace, loading],
  );

  return (
    <PlacesContext.Provider value={contextValues}>
      {children}
    </PlacesContext.Provider>
  );
};

PlacesProvider.propTypes = {
  children: propTypes.node.isRequired,
  event: propTypes.object.isRequired,
};
