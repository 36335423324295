import { useState, useRef } from 'react';
import html2canvas from 'html2canvas';
import { cameraStreamTypes } from '../../../../constants';

const usePhotoCapture = ({
  videoAspectRatio,
  capturedPhotoAspectRatio,
  setPhoto,
}) => {
  const videoRef = useRef(null);
  const [capturingPhoto, setCapturingPhoto] = useState(false);

  const handleCropImage = image => {
    const cropCanvas = document.createElement('canvas');
    cropCanvas.width = capturedPhotoAspectRatio.width;
    cropCanvas.height = capturedPhotoAspectRatio.height;

    const cropCtx = cropCanvas.getContext('2d');

    const clipStartXCoordinate =
      (capturedPhotoAspectRatio.width - videoAspectRatio.width) / 2;

    cropCtx.drawImage(
      image,
      clipStartXCoordinate,
      0,
      videoAspectRatio.width,
      videoAspectRatio.height,
    );

    // Definir qualidade de renderização
    cropCtx.imageSmoothingEnabled = true;
    cropCtx.imageSmoothingQuality = 'high';

    const croppedDataURL = cropCanvas.toDataURL();

    setPhoto(croppedDataURL);
  };

  const handleCropVideo = video => {
    const cropCanvas = document.createElement('canvas');
    const videoRect = video.getBoundingClientRect();

    const actualWidth = videoRect?.width;
    const actualHeight = videoRect?.height;

    cropCanvas.width = capturedPhotoAspectRatio.width;
    cropCanvas.height = capturedPhotoAspectRatio.height;

    const cropCtx = cropCanvas.getContext('2d');
    const clipStartXCoordinate =
      (capturedPhotoAspectRatio.width - actualWidth) / 2;

    cropCtx.drawImage(
      video,
      clipStartXCoordinate,
      0,
      actualWidth,
      actualHeight,
    );

    // Definir qualidade de renderização
    cropCtx.imageSmoothingEnabled = true;
    cropCtx.imageSmoothingQuality = 'high';

    const croppedDataURL = cropCanvas.toDataURL();

    setPhoto(croppedDataURL);
  };

  const captureScreenshot = cameraStreamType => {
    setCapturingPhoto(true);
    if (cameraStreamType === cameraStreamTypes.PORTER_VIDEO) {
      const video = videoRef.current;
      if (video) {
        handleCropVideo(video);
        setCapturingPhoto(false);
      }
    } else {
      html2canvas(videoRef.current, {
        useCORS: true,
      })
        .then(canvas => {
          handleCropImage(canvas);
        })
        .finally(() => setCapturingPhoto(false));
    }
  };

  return {
    videoRef,
    capturingPhoto,
    captureScreenshot,
  };
};

export default usePhotoCapture;
