import { useContext, useEffect, useMemo, useState } from 'react';
import propTypes from 'prop-types';
import { useFormikContext } from 'formik';
import * as yup from 'yup';
import { Trans, useTranslation } from 'react-i18next';
import { removeProperty } from '@kiper/fns';
import { useToggle } from '@kiper/hooks';
import { CallPriorities, ErrorMessage } from '@kiper/ui';
import {
  destinationTypes,
  profileTypes,
  callStatus,
  profileColor,
} from '../../../constants';
import {
  useGuidedAttendance,
  useCurrentLoggedContext,
  useVoice,
  useAttendanceRemoteConfig,
} from '../../../hooks';
import { GuidedAttendanceContext } from '../../../store';
import { NoKiperVoiceAgentModal, NoKiperVoiceModal } from '../../Modals';
import StepStructure, { StepTextTypeColor } from '../StepStructure';
import { useActionButtons, useMutations } from './hooks';
import useQueries from './hooks/useQueries';

const StepAttendanceCallDweller = ({ event }) => {
  const { setContextTree } = useContext(GuidedAttendanceContext);
  const { handleNextAction, handleGetTriageSelected } = useGuidedAttendance();
  const { clickToCall } = useVoice();
  const { loggedContext } = useCurrentLoggedContext();
  const { t } = useTranslation('guided-attendance');
  const [lastPersonContextIdCalled, setLastPersonContextIdCalled] = useState(
    null,
  );
  const [loadingCall, setLoadingCall] = useState(false);
  const [
    openNoKiperVoiceAgentModal,
    toggleOpenNoKiperVoiceAgentModal,
  ] = useToggle(false);
  const [openNoKiperVoiceModal, toggleOpenNoKiperVoiceModal] = useToggle(false);
  const { enableSyncFaceOnUploadImage } = useAttendanceRemoteConfig(
    loggedContext,
    event?.condominium,
  );

  const {
    values,
    setFieldValue,
    errors,
    touched,
    handleSubmit,
  } = useFormikContext();

  const hasKiperVoiceAgent = !!loggedContext?.personContextParams?.find(
    item => item.name === 'kiperVoiceAgent' && item.id,
  );

  const hasKiperVoice = loggedContext?.partner?.hasKiperVoice;

  const schema = yup.object({
    stepAttendanceCallDweller: yup.object({
      callStatus: yup
        .number()
        .test(
          'is-not-null',
          t('common:feedback.required-field'),
          value => value !== null,
        )
        .required(t('common:feedback.required-field'))
        .nullable(),
    }),
  });

  const { insertStatus, syncCondo } = useMutations();

  const {
    callPriorities,
    loading,
    handleGetCallPriorities,
    contactTypes,
    typeOfContacts,
  } = useQueries({
    unityId:
      values?.stepAttendanceGetUnity?.unity?.id ||
      values?.stepGetOnlyUnity?.unity?.id,
    condominiumPersonContextId: event?.condominium?.personContextId,
  });

  const groupOfUnityAndUnityValue = useMemo(() => {
    const unityGroup =
      values?.stepAttendanceGetUnity?.unityGroup?.description ||
      values?.stepGetOnlyUnity?.unityGroup;
    const unity =
      values?.stepAttendanceGetUnity?.unity?.description ||
      values?.stepGetOnlyUnity?.unity?.label;

    if (!unityGroup && !unity) return null;

    return `${unityGroup} - ${unity}`;
  }, [values?.stepAttendanceGetUnity]);

  const { actionButtons, selectedCallStatus } = useActionButtons({
    setFieldValue,
    values,
    t,
  });

  const handleClickToCall = callObject => {
    if (!hasKiperVoice) return toggleOpenNoKiperVoiceModal();

    if (hasKiperVoiceAgent) {
      const feedbackText =
        callObject.destinationKind === destinationTypes.intercomAta
          ? t('step-call-dwellers.making-call-to-extension', {
              number: callObject.destination,
            })
          : t('step-call-dwellers.making-call-to', {
              number: callObject.destination,
            });

      clickToCall({
        voiceCallInput: removeProperty(callObject, '__typename'),
        feedbackText,
        eventId: event?.eventId,
        partner: event?.partner?.name,
        condominium: event?.condominium?.name,
        eventCode: event?.eventType,
      });

      setLastPersonContextIdCalled(callObject?.personContextId);

      setLoadingCall(true);
      return setTimeout(() => setLoadingCall(false), 3000);
    }

    return toggleOpenNoKiperVoiceAgentModal();
  };

  const getProfileName = fieldName => {
    return t(`common:profiles.${profileTypes[fieldName]}`);
  };
  const getProfileColor = fieldName => {
    return profileColor[fieldName] || 'highBlack';
  };

  const nextAction = async value => {
    const { stepFindRegister } = values;
    const { transientPersonId, hasRegisteredFace } = stepFindRegister || {};

    const enabledToSyncFace =
      enableSyncFaceOnUploadImage &&
      value?.stepAttendanceCallDweller?.callStatus === callStatus.AUTHORIZED &&
      !!transientPersonId &&
      hasRegisteredFace;

    await insertStatus({
      variables: {
        eventId: event?.eventId,
        callStatusBody: {
          callStatus: value?.stepAttendanceCallDweller?.callStatus,
          condominiumPersonContextId: event?.condominium?.personContextId,
        },
      },
    });

    if (enabledToSyncFace) {
      syncCondo({
        variables: {
          transientPersonId: String(transientPersonId),
          eventId: event?.eventId,
          condominiumId: String(event?.condominium?.personContextId),
        },
      });
    }

    return handleNextAction({
      values: value,
      fieldValue: 'stepAttendanceCallDweller',
    });
  };

  useEffect(() => {
    const hasCallStatus =
      values?.stepAttendanceCallDweller &&
      values?.stepAttendanceCallDweller?.callStatus !== null &&
      selectedCallStatus;

    if (hasCallStatus) handleSubmit();
  }, [values?.stepAttendanceCallDweller?.callStatus]);

  useEffect(() => {
    setContextTree({
      nextAction: async stepData => {
        const nextStep = await nextAction(stepData);
        return { nextStep, currentStepData: stepData };
      },
    });
  }, [lastPersonContextIdCalled, callPriorities]);

  useEffect(() => {
    if (!values?.stepAttendanceCallDweller?.callStatus) {
      setFieldValue('stepAttendanceCallDweller', { callStatus: null });
    }

    setContextTree({
      formikSchema: schema,
    });
  }, []);

  return (
    <StepStructure
      type={StepTextTypeColor.SPEAK}
      text={t('step-call-dwellers.title', {
        triage: t(
          `step-call-dwellers.triage.${handleGetTriageSelected(values)}`,
        ),
        name: values?.stepAttendanceGetName?.name,
      })}
      hasPreviousStepButton
    >
      <>
        <CallPriorities
          options={callPriorities}
          t={e => t(`step-call-dwellers.${e}`)}
          title={
            <Trans>
              {typeOfContacts === contactTypes.CONDOMINIUM
                ? t('step-call-dwellers.condominium-contacts')
                : t(`step-call-dwellers.dwellers-of`, {
                    groupOfUnityAndUnity: groupOfUnityAndUnityValue,
                  })}
            </Trans>
          }
          subtitle={<Trans>{t(`step-call-dwellers.contact-list`)}</Trans>}
          actionButtons={actionButtons}
          callStatus={callStatus}
          clickToCall={handleClickToCall}
          loadingCall={loadingCall}
          destinationTypes={destinationTypes}
          getProfileName={getProfileName}
          getProfileColor={getProfileColor}
          handleGetCallPriorities={handleGetCallPriorities}
          contactTypes={contactTypes}
          typeOfContacts={typeOfContacts}
          loading={loading}
        />
        {!!errors?.stepAttendanceCallDweller?.callStatus &&
          !!touched?.stepAttendanceCallDweller?.callStatus && (
            <ErrorMessage>
              {errors.stepAttendanceCallDweller.callStatus}
            </ErrorMessage>
          )}
      </>

      {openNoKiperVoiceAgentModal && (
        <NoKiperVoiceAgentModal
          open={openNoKiperVoiceAgentModal}
          onToggle={toggleOpenNoKiperVoiceAgentModal}
        />
      )}

      {openNoKiperVoiceModal && (
        <NoKiperVoiceModal
          open={openNoKiperVoiceModal}
          onToggle={toggleOpenNoKiperVoiceModal}
        />
      )}
    </StepStructure>
  );
};

export default StepAttendanceCallDweller;

StepAttendanceCallDweller.propTypes = {
  event: propTypes.object.isRequired,
};
