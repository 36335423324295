import { createContext, useState, useMemo } from 'react';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const GUIDED_ATTENDANCE_SETTINGS_TABS = Object.freeze({
  CONDOMINIUMS: 'condominiums',
  ATTENDANTS: 'attendants',
  PARTNERS: 'partners',
});

export const GuidedAttendanceSettingsTabContext = createContext(null);

export function GuidedAttendanceSettingsTabProvider({ children }) {
  const { t } = useTranslation('guided-attendance-settings');
  const [activeTab, setActiveTab] = useState(
    GUIDED_ATTENDANCE_SETTINGS_TABS.CONDOMINIUMS,
  );

  const tabs = [
    {
      title: t('list.condominiums'),
      tabId: GUIDED_ATTENDANCE_SETTINGS_TABS.CONDOMINIUMS,
      showTab: true,
    },
    {
      title: t('list.attendants'),
      tabId: GUIDED_ATTENDANCE_SETTINGS_TABS.ATTENDANTS,
      showTab: true,
    },
    {
      title: t('list.partners'),
      tabId: GUIDED_ATTENDANCE_SETTINGS_TABS.PARTNERS,
      showTab: true,
    },
  ];

  const handleChangeTab = newTab => {
    if (newTab !== activeTab) setActiveTab(newTab);
  };

  const value = useMemo(
    () => ({
      activeTab,
      handleChangeTab,
      tabs,
      tabIds: GUIDED_ATTENDANCE_SETTINGS_TABS,
    }),
    [activeTab],
  );

  return (
    <GuidedAttendanceSettingsTabContext.Provider value={value}>
      {children}
    </GuidedAttendanceSettingsTabContext.Provider>
  );
}

GuidedAttendanceSettingsTabProvider.propTypes = {
  children: propTypes.oneOfType([propTypes.node, propTypes.element]),
};

GuidedAttendanceSettingsTabProvider.defaultProps = {
  children: <></>,
};
