import { useEffect, useState, useMemo } from 'react';
import propTypes from 'prop-types';
import { SplitSidebar } from '@kiper/ui';
import { FiBook, FiFileText } from 'react-icons/fi';
import { MdApartment } from 'react-icons/md';
import {
  useCurrentLoggedContext,
  useGuidedAttendance,
} from '../../../../hooks';
import { sendAmplitudeData } from '../../../../services/amplitude';
import {
  CondominiumRules,
  HowAct,
  ContinuousAttendance,
  LastAttendanceEvents,
  CondominiumRuleQuestionsAnswers,
} from '../../../../components/QuickMenuParts';
import * as S from './styles';
import CondoInfo from '../../../../components/QuickMenuParts/CondominiumRules/CondoInfo';

function GuidedQuickMenu({
  event,
  condominiumName,
  viewTemplate,
  condominiumPersonContextId,
}) {
  const { loggedContext } = useCurrentLoggedContext();
  const { handleValidHasSubtriage } = useGuidedAttendance();
  const [registerAccessAmplitude, setRegisterAccessAmplitude] = useState(false);
  const [selected, setSelected] = useState(viewTemplate?.sideMenu?.menu);

  const { condoRules, howAct, condoInfo } = useMemo(
    () => ({
      condoRules: selected === 'condo-rules',
      howAct: selected === 'how-act',
      condoInfo: selected === 'condo-info',
    }),
    [selected],
  );

  const footerComponentName = viewTemplate?.sideMenu?.footer?.componentName;

  useEffect(() => {
    if (registerAccessAmplitude) {
      sendAmplitudeData(`guided quick menu select option`, {
        pages: selected,
        condominium: Number(condominiumPersonContextId),
        user: Number(loggedContext?.personContextId),
      });
    } else {
      setRegisterAccessAmplitude(true);
    }
  }, [condoRules, howAct, condoInfo]);

  const handleSelect = value => setSelected(value);

  const quickMenuItemsList = [
    {
      icon: <FiBook size={24} />,
      value: 'condo-rules',
      hasSubtriage: false,
    },
    {
      icon: <FiFileText size={24} />,
      value: 'how-act',
      hasSubtriage: false,
    },
    {
      icon: <MdApartment size={24} />,
      value: 'condo-info',
      hasSubtriage: true,
    },
  ];

  const footerComponent = {
    ContinuousAttendance: (
      <ContinuousAttendance
        eventId={event?.eventId}
        condominiumPersonContextId={condominiumPersonContextId}
      />
    ),
    LastAttendanceEvents: (
      <LastAttendanceEvents
        eventType={viewTemplate?.eventType}
        eventId={event?.eventId}
        condominiumPersonContextId={condominiumPersonContextId}
      />
    ),
  };

  const hasSubtriage = useMemo(() => {
    return handleValidHasSubtriage(event?.condominium);
  }, [event]);

  const ToolItemsFiltered = useMemo(() => {
    return quickMenuItemsList.filter(item => {
      return !item.hasSubtriage || item.hasSubtriage === hasSubtriage;
    });
  }, [event, hasSubtriage]);

  const ToolItems = ToolItemsFiltered.map(item => (
    <SplitSidebar.ToolItem
      onSelect={handleSelect}
      active={selected === item.value}
      value={item.value}
      key={item.value}
    >
      {item.icon}
    </SplitSidebar.ToolItem>
  ));

  return (
    <SplitSidebar toolItems={ToolItems}>
      <>
        <SplitSidebar.SplitContentPane>
          <>
            {condoRules && (
              <>
                {hasSubtriage ? (
                  <CondominiumRuleQuestionsAnswers
                    condominiumPersonContextId={condominiumPersonContextId}
                    event={event}
                  />
                ) : (
                  <CondominiumRules
                    condominiumName={condominiumName}
                    condominiumPersonContextId={condominiumPersonContextId}
                    event={event}
                  />
                )}
              </>
            )}
            {howAct && (
              <HowAct
                condominiumPersonContextId={condominiumPersonContextId}
                eventType={event?.eventType}
              />
            )}
            {condoInfo && (
              <>
                {hasSubtriage && (
                  <CondoInfo
                    condominiumPersonContextId={condominiumPersonContextId}
                  />
                )}
              </>
            )}
          </>
        </SplitSidebar.SplitContentPane>
        {footerComponent[footerComponentName] && (
          <>
            <SplitSidebar.Divider />
            <S.DynamicPaneSizeContainer>
              <SplitSidebar.SplitContentPane flex={0} height="100%" noScroll>
                {footerComponent[footerComponentName]}
              </SplitSidebar.SplitContentPane>
            </S.DynamicPaneSizeContainer>
          </>
        )}
      </>
    </SplitSidebar>
  );
}

export default GuidedQuickMenu;

GuidedQuickMenu.propTypes = {
  condominiumName: propTypes.string,
  event: propTypes.object.isRequired,
  condominiumPersonContextId: propTypes.number,
  eventType: propTypes.number,
  viewTemplate: propTypes.shape({
    eventType: propTypes.number.isRequired,
    sideMenu: propTypes.shape({
      footer: propTypes.any.isRequired,
      menu: propTypes.string.isRequired,
    }).isRequired,
  }),
};

GuidedQuickMenu.defaultProps = {
  condominiumName: null,
  condominiumPersonContextId: null,
  eventType: null,
  viewTemplate: null,
};
