import { useState, useMemo, useEffect, useCallback } from 'react';
import moment from 'moment';
import { queries, mutations } from '@kiper/monitoring-graphql/report';
import { useLazyQuery, useMutation } from 'react-apollo';
import { saveAs } from 'file-saver';
import { useSwal } from '@kiper/hooks';
import { apolloErrorHandler, toLocaleDateString } from '@kiper/fns';
import useCurrentLoggedContext from '../../../hooks/useCurrentLoggedContext';
import { sendAmplitudeData } from '../../../services/amplitude';

const initialPagination = {
  page: 1,
  totalPages: 1,
  pageSize: 10,
  totalResults: 0,
  hasPrevious: false,
  hasNext: false,
};

export const SCREEN_STATE = {
  EMPTY: 'EMPTY',
  DATA: 'DATA',
  GENERATING: 'GENERATING',
  DONE: 'DONE',
  ERROR: 'ERROR',
  LOADING: 'LOADING',
  CANCELED: 'CANCELED',
};

const initialPaginationFilters = {
  page: 1,
  pageSize: 10,
};

const EXPORT_TYPE = {
  PDF: 'PDF',
  XLS: 'XLS',
  CSV: 'CSV',
};

export const useReportFilters = ({ activeTab, formProps = {}, t }) => {
  const [screenState, setScreenState] = useState(SCREEN_STATE.EMPTY);
  const [exportType, setExportType] = useState(null);
  const [filters, setFilters] = useState(null);

  const [xlsFileName, setXlsFileName] = useState('');
  const [isXlsCanceled, setXlsCanceled] = useState(false);
  const [xlsData, setXlsData] = useState(null);

  const [csvFileName, setCsvFileName] = useState('');
  const [isCsvCanceled, setCsvCanceled] = useState(false);
  const [csvData, setCsvData] = useState(null);

  const [generationDate, setGenerationDate] = useState(new Date());

  const [paginationFilters, setPaginationFilters] = useState(
    initialPaginationFilters,
  );
  const [usersData, setUsersData] = useState({
    collection: [],
    pagination: initialPagination,
  });
  const [eventsData, setEventsData] = useState({
    collection: [],
    pagination: initialPagination,
  });
  const [vehiclesData, setVehiclesData] = useState({
    collection: [],
    pagination: initialPagination,
  });

  const [headerFilters, setHeaderFilters] = useState({});

  const [condominiumName, setCondominiumName] = useState('');

  const { loggedContext } = useCurrentLoggedContext();

  const { reportId, isCustom, reportFilters } = formProps;
  const { toast } = useSwal();

  const updatedScreenState = useMemo(
    () =>
      screenState === SCREEN_STATE.EMPTY ||
      screenState === SCREEN_STATE.DATA ||
      screenState === SCREEN_STATE.DONE
        ? SCREEN_STATE.DATA
        : SCREEN_STATE.DONE,
    [screenState],
  );

  const onError = err => {
    const formattedErrors = apolloErrorHandler(err);
    if (formattedErrors && formattedErrors.length) {
      toast.fire({ title: formattedErrors.join('\n'), icon: 'error' });
    }
    setScreenState(SCREEN_STATE.ERROR);
  };

  const [users, { loading: usersLoading }] = useLazyQuery(
    queries.usersReportData,
    {
      fetchPolicy: 'network-only',
      onCompleted: ({ usersReportData }) => {
        setGenerationDate(new Date());
        if (screenState !== SCREEN_STATE.ERROR)
          setScreenState(updatedScreenState);
        setUsersData(usersReportData);
        setXlsCanceled(false);
        setCsvCanceled(false);
      },
      onError,
    },
  );

  const [events, { loading: eventsLoading }] = useLazyQuery(
    queries.eventsReportData,
    {
      fetchPolicy: 'network-only',
      onCompleted: ({ eventsReportData }) => {
        setGenerationDate(new Date());
        if (screenState !== SCREEN_STATE.ERROR)
          setScreenState(updatedScreenState);
        setEventsData(eventsReportData);
        setXlsCanceled(false);
        setCsvCanceled(false);
      },
      onError,
    },
  );

  const [vehicles, { loading: vehiclesLoading }] = useLazyQuery(
    queries.vehiclesReportData,
    {
      fetchPolicy: 'network-only',
      onCompleted: ({ vehiclesReportData }) => {
        setGenerationDate(new Date());
        if (screenState !== SCREEN_STATE.ERROR)
          setScreenState(updatedScreenState);
        setVehiclesData(vehiclesReportData);
        setXlsCanceled(false);
        setCsvCanceled(false);
      },
      onError,
    },
  );

  const resetPaginationFilters = () => {
    setPaginationFilters(initialPaginationFilters);
  };

  const mapFilters = filtersData => {
    return reportFilters?.reduce((result, filter) => {
      const filterValue = filtersData?.[filter.key];

      if (filterValue) {
        const isMulti = !(
          typeof filterValue === 'string' || typeof filterValue === 'number'
        );
        result.push({
          filterId: filter.id,
          value: isMulti
            ? filterValue.map(current => current?.value ?? current)
            : filterValue,
        });
      }
      return result;
    }, []);
  };

  const generateQuery = useCallback(
    (data, pagination) => {
      return {
        id: reportId,
        isCustomReport: isCustom,
        filters: mapFilters(data)
          ?.map(i => `filters=${encodeURI(JSON.stringify(i))}`)
          ?.join('&')
          ?.toString(),
        ...pagination,
      };
    },
    [activeTab, reportId, isCustom, paginationFilters],
  );

  const fetch = (
    data,
    generateReport = false,
    pagination = initialPaginationFilters,
  ) => {
    if (data) {
      setFilters(data);
      setScreenState(SCREEN_STATE.DATA);

      if (generateReport) setPaginationFilters(initialPaginationFilters);

      const query = {
        variables: {
          reportData: generateQuery(data, pagination),
        },
      };

      switch (activeTab) {
        case 'usersReport':
          sendAmplitudeData('users report', {
            user: loggedContext?.email,
            partner: loggedContext?.partner?.name,
            condominiumFilter: data.condominiumTreeNodeId,
            profilesFilter: data?.profileIds?.map(item => item.value).join(),
            nodeIdFilter: data.nodeId,
            daysRange: moment(data.endDate).diff(moment(data.initDate), 'days'),
            initDateRangeFromNow: moment().diff(moment(data.initDate), 'days'),
          });
          return users(query);
        case 'eventsReport':
          sendAmplitudeData('events report', {
            user: loggedContext?.email,
            partner: loggedContext?.partner?.name,
            condominiumFilter: data.condominiumTreeNodeId,
            endDateFilter: data.endDate,
            initDateFilter: data.initDate,
            eventTypesFilter: data?.eventTypes
              ?.map(item => (item.value ? item.value : item))
              .join(),
            searchTextFilter: data?.searchText,
            daysRange: moment(data.endDate).diff(moment(data.initDate), 'days'),
            initDateRangeFromNow: moment().diff(moment(data.initDate), 'days'),
          });
          return events(query);
        case 'vehiclesReport':
          sendAmplitudeData('vehicles report', {
            user: loggedContext?.email,
            partner: loggedContext?.partner?.name,
            condominiumFilter: data.condominiumTreeNodeId,
            endDateFilter: data.endDate,
            initDateFilter: data.initDate,
            eventTypesFilter: data?.eventTypes
              ?.map(item => (item.value ? item.value : item))
              .join(),
            searchTextFilter: data?.searchText,
            daysRange: moment(data.endDate).diff(moment(data.initDate), 'days'),
            initDateRangeFromNow: moment().diff(moment(data.initDate), 'days'),
          });
          return vehicles(query);
        default:
          return null;
      }
    }

    return null;
  };

  const exportPdf = () => {
    setScreenState(SCREEN_STATE.GENERATING);
    setExportType(EXPORT_TYPE.PDF);

    const query = {
      variables: {
        reportData: generateQuery(filters, { page: -1, pageSize: 10 }),
      },
    };

    switch (activeTab) {
      case 'usersReport':
        return users(query);
      case 'eventsReport':
        return events(query);
      case 'vehiclesReport':
        return vehicles(query);
      default:
        return null;
    }
  };

  const downloadFile = ({ data, contentType } = {}, fileName) => {
    if (data && contentType) {
      const unitArray = Uint8Array.from(data);
      const blob = new Blob([unitArray], { type: contentType });

      saveAs(blob, fileName);
    }
  };

  const [generateXls, { loading: loadingXls }] = useMutation(
    mutations.generateReportXls,
    {
      onError: err => {
        if (!isXlsCanceled) {
          const buffer = err?.graphQLErrors?.[0]?.extensions?.response?.data;
          if (buffer) {
            const bufferOriginal = Buffer.from(buffer);
            const bufferToString = bufferOriginal.toString('utf8');
            if (bufferToString) {
              const json = JSON.parse(bufferToString)?.[0];
              toast.fire({ title: json?.message, icon: 'error' });
            }
          } else {
            onError(err);
          }
        } else {
          setScreenState(SCREEN_STATE.DATA);
          setXlsCanceled(false);
        }
      },
      onCompleted: data => {
        if (!isXlsCanceled) {
          setScreenState(SCREEN_STATE.DONE);
          setXlsData(data.generateReportXls);
        } else {
          setXlsCanceled(false);
        }
      },
    },
  );

  const [generateCsv, { loading: loadingCsv }] = useMutation(
    mutations.generateReportCsv,
    {
      onError: err => {
        if (!isCsvCanceled) {
          const buffer = err?.graphQLErrors?.[0]?.extensions?.response?.data;
          if (buffer) {
            const bufferOriginal = Buffer.from(buffer);
            const bufferToString = bufferOriginal.toString('utf8');
            if (bufferToString) {
              const json = JSON.parse(bufferToString)?.[0];
              toast.fire({ title: json?.message, icon: 'error' });
            }
          } else {
            onError(err);
          }
        } else {
          setScreenState(SCREEN_STATE.DATA);
          setCsvCanceled(false);
        }
      },
      onCompleted: data => {
        if (!isCsvCanceled) {
          setScreenState(SCREEN_STATE.DONE);
          setCsvData(data.generateReportCsv);
        } else {
          setCsvCanceled(false);
        }
      },
    },
  );

  const onResetReport = () => {
    if (filters) {
      setScreenState(SCREEN_STATE.DATA);

      const query = {
        variables: {
          reportData: generateQuery(filters, initialPaginationFilters),
        },
      };

      switch (activeTab) {
        case 'usersReport':
          return users(query);
        case 'eventsReport':
          return events(query);
        case 'vehiclesReport':
          return vehicles(query);
        default:
          return null;
      }
    }

    return () => null;
  };

  const onCanceled = () => {
    if (screenState === SCREEN_STATE.GENERATING) {
      toast.fire({ title: t('details.generation-canceled'), icon: 'error' });
    }
  };

  const [cancel] = useLazyQuery(queries.cancelReport, {
    variables: { id: formProps?.reportId },
    onCompleted: () => {
      setXlsCanceled(false);
      setCsvCanceled(false);
      onCanceled();
      onResetReport();
    },
    onError: () => {
      onCanceled();
      setXlsCanceled(false);
      setCsvCanceled(false);
    },
    fetchPolicy: 'network-only',
  });

  const handleCancelXls = () => {
    if (!isXlsCanceled && screenState === SCREEN_STATE.GENERATING) {
      setXlsCanceled(true);
      cancel();
    } else {
      onResetReport();
    }
  };

  const exportXls = () => {
    setScreenState(SCREEN_STATE.GENERATING);
    setExportType(EXPORT_TYPE.XLS);

    generateXls({
      variables: {
        reportId: Number(reportId),
        isCustom,
        filters: JSON.stringify(mapFilters(filters)),
      },
    });
  };

  const handleCancelCsv = () => {
    if (!isCsvCanceled && screenState === SCREEN_STATE.GENERATING) {
      setCsvCanceled(true);
      cancel();
    } else {
      onResetReport();
    }
  };

  const exportCsv = () => {
    setScreenState(SCREEN_STATE.GENERATING);
    setExportType(EXPORT_TYPE.CSV);

    generateCsv({
      variables: {
        reportId: Number(reportId),
        isCustom,
        filters: JSON.stringify(mapFilters(filters)),
      },
    });
  };

  const handleCancelPrint = () => {
    switch (exportType) {
      case EXPORT_TYPE.PDF:
        return onResetReport();
      case EXPORT_TYPE.XLS:
        return handleCancelXls();
      case EXPORT_TYPE.CSV:
        return handleCancelCsv();
      default:
        return onResetReport();
    }
  };

  const handlePrint = () => {
    switch (exportType) {
      case EXPORT_TYPE.PDF:
        return window.print();
      case EXPORT_TYPE.XLS:
        return downloadFile(xlsData, xlsFileName);
      case EXPORT_TYPE.CSV:
        return downloadFile(csvData, csvFileName);
      default:
        return () => null;
    }
  };

  const header = useMemo(
    () => ({
      selectedFilters: headerFilters,
      generationDate: toLocaleDateString(generationDate),
      condominiumName,
    }),
    [headerFilters],
  );

  useEffect(() => {
    if (paginationFilters.page !== 1) {
      setPaginationFilters(f => {
        return { ...f, page: 1 };
      });
    }
  }, [filters]);

  const loading = useMemo(
    () =>
      eventsLoading ||
      usersLoading ||
      vehiclesLoading ||
      loadingXls ||
      loadingCsv,
    [eventsLoading, usersLoading, vehiclesLoading, loadingXls, loadingCsv],
  );

  const handlePageSize = pagesize => {
    if (!loading) {
      setPaginationFilters(x => ({
        ...x,
        pageSize: Number(pagesize),
        page: 1,
      }));
      if (filters)
        fetch(filters, false, {
          pageSize: Number(pagesize),
          page: 1,
        });
    }
  };

  const handlePageChange = increment => {
    if (!loading) {
      setPaginationFilters(x => ({ ...x, page: x.page + increment }));
      if (filters)
        fetch(filters, false, {
          ...paginationFilters,
          page: paginationFilters.page + increment,
        });
    }
  };

  const toggleFilters = () => {
    setFilters(null);
    resetPaginationFilters();
    setScreenState(SCREEN_STATE.EMPTY);
  };

  return {
    paginationFilters,
    loading,
    screenState,
    usersData,
    eventsData,
    vehiclesData,
    exportType,
    header,
    fetch,
    handlePageSize,
    handlePageChange,
    toggleFilters,
    exportPdf,
    exportXls,
    exportCsv,
    handleCancelPrint,
    handlePrint,
    setXlsFileName,
    setCsvFileName,
    setCondominiumName,
    setHeaderFilters,
    resetPaginationFilters,
  };
};
