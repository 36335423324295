export const getCondominiumParam = ({ paramsList, paramName }) => {
  if (!paramsList || !Array.isArray(paramsList) || !paramName.length) return [];
  return paramsList?.filter(item => item.name === paramName) || [];
};

export const getCondominiumParams = ({ paramsList, paramsNames }) => {
  if (!paramsList || !Array.isArray(paramsList) || !paramsNames.length)
    return [];
  return paramsList?.filter(item => paramsNames.includes(item.name)) || [];
};
