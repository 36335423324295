import { useMutation } from 'react-apollo';
import { createActivity } from '@kiper/monitoring-graphql/guided_attendance';
import { useSwal } from '@kiper/hooks';
import { useFormatApolloErrorHandler } from '../../../../../../hooks';
import { activityTypes } from '../../../../../../constants';

const useRegisterActivity = (loading, setVisibleRuleReview, t) => {
  const { onError } = useFormatApolloErrorHandler();
  const { toast } = useSwal();

  const [registerActivity, { loading: createLoading }] = useMutation(
    createActivity,
    {
      onCompleted() {
        toast.fire({
          title: t('request-review-success'),
          icon: 'success',
        });
        setVisibleRuleReview(null);
      },
      onError,
    },
  );

  const handleRegisterRuleReviewActivity = (
    e,
    event,
    question,
    triageDescription,
    subtriageDescription,
  ) => {
    e.stopPropagation();

    if (createLoading) return;

    const partner = {
      personContextId: event?.partner?.personContextId,
      name: event?.partner?.name,
    };

    const condominium = {
      personContextId: event?.condominium?.personContextId,
      name: event?.condominium?.name,
    };

    const reviewActivityPayload = {
      triageName: triageDescription,
      subtriageName: subtriageDescription,
      ruleQuestion: question?.description,
      questionAnswerType: question?.answer?.questionAnswerType,
      partner,
      condominium,
    };

    registerActivity({
      variables: {
        eventId: event?.eventId,
        activityTypeId: activityTypes.AttendantRuleReview,
        data: JSON.stringify(reviewActivityPayload),
      },
    });
  };

  return {
    handleRegisterRuleReviewActivity,
  };
};

export default useRegisterActivity;
