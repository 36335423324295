import propTypes from 'prop-types';
import { useMutation } from 'react-apollo';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { mutation } from '@kiper/monitoring-graphql/guided_attendance_settings';
import { apolloErrorHandler, runtimeConfig } from '@kiper/fns';
import { useSwal } from '@kiper/hooks';
import { Button, ErrorMessage, Flex, Label, Select } from '@kiper/ui';
import { useCurrentLoggedContext } from '../../../../hooks';
import PersonSelect from '../../../../components/PersonSelect';
import PageHeader from '../../../../components/PageHeader';

import * as S from './styles';

function EnableAttendant({ history, route }) {
  const { loggedContext } = useCurrentLoggedContext();
  const { toast } = useSwal();
  const { t } = useTranslation('guided-attendance-settings');

  const schema = yup.object({
    attendant: yup.object().shape({
      value: yup.number().required(t('common:feedback.required-field')),
      label: yup.string(),
    }),
    eventType: yup.object().shape({
      value: yup.number().required(t('common:feedback.required-field')),
      label: yup.string(),
    }),
    attendanceStep: yup.object().shape({
      value: yup.number().required(t('common:feedback.required-field')),
      label: yup.string(),
    }),
  });

  const isProduction = runtimeConfig.RAZZLE_ENV === 'production';

  const eventOptions = [
    { value: 900, label: `900 - ${t('event:900')}` },
    { value: 102, label: `102 - ${t('event:102')}` },
  ];

  const attendanceStepOptions = [
    {
      value: isProduction ? 16 : 29,
      label: t('register.attendant.old-attendance'),
    },
    {
      value: isProduction ? 71 : 15,
      label: t('register.attendant.with-opening-doors'),
    },
    {
      value: isProduction ? 24 : 83,
      label: t('register.attendant.without-opening-doors'),
    },
  ];

  const [createAttendant, { loading }] = useMutation(
    mutation.createGuidedAttendanceAttendant,
    {
      onCompleted: () => {
        history.push('/guided-attendance-settings');
        toast.fire({
          title: t('register.attendant.success'),
          icon: 'success',
        });
      },
      onError: err => {
        const formattedErrors = apolloErrorHandler(err);
        if (formattedErrors && formattedErrors.length) {
          toast.fire({ title: formattedErrors.join('\n'), icon: 'error' });
          handleReset(); // eslint-disable-line
        }
      },
    },
  );

  const onSubmit = values => {
    createAttendant({
      variables: {
        body: {
          attendantPersonContextId: values?.attendant?.contextId,
          eventType: values.eventType.value,
          attendanceStepId: values.attendanceStep.value,
        },
      },
    });
  };

  const {
    handleSubmit,
    values,
    setFieldValue,
    errors,
    touched,
    handleReset,
  } = useFormik({
    validationSchema: schema,
    initialValues: {
      attendant: '',
      eventType: '',
      attendanceStep: '',
    },
    onSubmit,
  });

  return (
    <>
      <PageHeader
        breadcrumb={route.breadcrumb}
        t={t}
        title={t('register.attendant.header-title')}
      />
      <S.Container>
        <S.Form onSubmit={handleSubmit}>
          <Flex w="100%" gridGap="16px">
            <Flex flexDirection="column">
              <Label>{t('register.attendant.select-attendant')}</Label>
              <PersonSelect
                width="300px"
                placeholder={t('register.attendant.select-attendant')}
                name="sourceNodeId"
                sourceNodeId={loggedContext.topNodeId}
                fieldName={['operator', 'operationSupervisor', 'doorman']}
                value={values?.attendant}
                isClearable
                onChange={e => setFieldValue('attendant', e)}
                invalid={touched?.attendant && !!errors?.attendant}
                getOptionValue={option => option?.contextId}
                getOptionLabel={option =>
                  `${option.personContextId} - ${option.label}`
                }
              />

              {!!touched?.condominium && !!errors?.condominium && (
                <ErrorMessage>{errors.condominium?.contextId}</ErrorMessage>
              )}
            </Flex>
            <Flex flexDirection="column">
              <Label>{t('register.attendant.select-event')}</Label>
              <Select
                options={eventOptions}
                isClearable
                name="eventType"
                onChange={e => setFieldValue('eventType', e)}
                value={values?.eventType}
                placeholder={t('register.attendant.select-event')}
                loading={false}
                invalid={touched.eventType && !!errors.eventType}
              />
              {!!touched.eventType && !!errors.eventType && (
                <ErrorMessage>{errors.eventType?.value}</ErrorMessage>
              )}
            </Flex>

            {values?.eventType && (
              <Flex flexDirection="column">
                <Label>{t('register.attendant.select-attendance')}</Label>
                <Select
                  options={attendanceStepOptions}
                  isClearable
                  name="attendanceStep"
                  onChange={e => setFieldValue('attendanceStep', e)}
                  value={values?.attendanceStep}
                  placeholder={t('register.attendant.select-attendance')}
                  loading={false}
                  invalid={touched?.attendanceStep && !!errors?.attendanceStep}
                />
                {!!touched?.attendanceStep && !!errors?.attendanceStep && (
                  <ErrorMessage>{errors.attendanceStep?.value}</ErrorMessage>
                )}
              </Flex>
            )}
          </Flex>
          <Flex w="100%" justifyContent="flex-end">
            <Button color="primary" type="submit" disabled={loading}>
              {loading ? t('buttons:saving') : t('register.attendant.enable')}
            </Button>
          </Flex>
        </S.Form>
      </S.Container>
    </>
  );
}

export default EnableAttendant;
EnableAttendant.propTypes = {
  route: propTypes.object.isRequired,
  history: propTypes.object.isRequired,
};
