import propTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import { Flex, Text } from '@kiper/ui';
import {
  profileTypes,
  authorizationStatus,
  profileColor,
} from '../../../constants';

const PersonAuthorization = ({ item, handleSelectOption }) => {
  const { t } = useTranslation('guided-attendance');

  const handleStatusColor = status => {
    switch (status) {
      case authorizationStatus.NOT_AUTHORIZED:
        return 'danger500';
      case authorizationStatus.AUTHORIZED:
        return 'primary500';
      case authorizationStatus.REQUEST_AUTHORIZATION:
        return 'highBlack';
      case authorizationStatus.CONFIRM_DATA:
        return 'danger300';
      default:
        return 'highBlack';
    }
  };

  const handleStatusValue = status => {
    switch (status) {
      case authorizationStatus.NOT_AUTHORIZED:
        return t('step-find-register.status.not-authorized');
      case authorizationStatus.AUTHORIZED:
        return t('step-find-register.status.authorized');
      case authorizationStatus.REQUEST_AUTHORIZATION:
        return t('step-find-register.status.request-authorization');
      case authorizationStatus.CONFIRM_DATA:
        return t('step-find-register.status.confirm-data');
      default:
        return t('step-find-register.status.request-authorization');
    }
  };

  const handleTypeValue = profileName => {
    return t(`common:profiles.${profileTypes[profileName]}`);
  };

  return (
    <Flex
      width="100%"
      flexDirection="column"
      gridGap="4px"
      onClick={e => handleSelectOption(e, item)}
    >
      <Flex justifyContent="space-between">
        <Flex flexDirection="column" flex="0.67">
          <Text color={profileColor[item?.profileName]}>
            {handleTypeValue(item?.profileName)}
          </Text>
          <Text>{item.name}</Text>
        </Flex>
        <Flex
          flex="0.33"
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="end"
        >
          <Text>{t('step-find-register.procedure')}</Text>
          <Trans>
            <Text color={handleStatusColor(item?.status)}>
              {handleStatusValue(item?.status)}
            </Text>
          </Trans>
        </Flex>
      </Flex>
      <Flex gridGap="16px">
        {item?.documentCpf && (
          <Text>
            {t(`step-find-register.document-cpf`, {
              cpf: item?.documentCpf,
            })}
          </Text>
        )}
        {item?.phoneNumber && (
          <Text>
            {t(`step-find-register.phone-number`, {
              phoneNumber: item?.phoneNumber,
            })}
          </Text>
        )}
        {item?.vehiclePlate && (
          <Text>
            {t(`step-find-register.vehicle-plate`, {
              vehiclePlate: item?.vehiclePlate,
            })}
          </Text>
        )}
      </Flex>
    </Flex>
  );
};

export default PersonAuthorization;

PersonAuthorization.propTypes = {
  item: propTypes.object.isRequired,
  handleSelectOption: propTypes.func.isRequired,
};
