import {
  eventLogInviteRegistration,
  insertCallStatus,
  syncCondoByTransientPhoto,
} from '@kiper/monitoring-graphql/guided_attendance';
import { useMutation } from 'react-apollo';

const useMutations = () => {
  const [insertStatus] = useMutation(insertCallStatus);
  const [insertEventLogInvite] = useMutation(eventLogInviteRegistration);
  const [syncCondo] = useMutation(syncCondoByTransientPhoto);

  return { insertStatus, insertEventLogInvite, syncCondo };
};

export default useMutations;
