import { useEffect, useState, useRef } from 'react';
import { useLazyQuery, useMutation } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import { useSwal } from '@kiper/hooks';
import { FaTrash } from 'react-icons/fa';
import { apolloErrorHandler } from '@kiper/fns';
import {
  query,
  mutation,
} from '@kiper/monitoring-graphql/guided_attendance_settings';

import { Button, Table, TableLoader } from '@kiper/ui';
import { ActionButton, TableContainer } from './styles';

const GuidedAttendanceCondominiums = () => {
  const { toast, confirm } = useSwal();
  const [
    guidedAttendanceCondominiums,
    setGuidedAttendanceCondominiums,
  ] = useState([]);
  const [t] = useTranslation('guided-attendance-settings');

  const columns = useRef([
    {
      name: t('list.condominium.table-columns.condominium'),
      align: 'left',
    },
    {
      name: t('list.condominium.table-columns.event-type'),
      align: 'left',
    },
    {
      name: t('list.condominium.table-columns.actions'),
      align: 'right',
    },
  ]);

  const [getAll, { loading }] = useLazyQuery(
    query.getGuidedAttendanceSettings,
    {
      fetchPolicy: 'no-cache',
      onCompleted: ({ guidedAttendanceSettings: data }) => {
        if (data?.guidedAttendanceSettings?.length) {
          const guidedAttendancesWithCondo = data.guidedAttendanceSettings.filter(
            x => x.condominium?.id,
          );
          setGuidedAttendanceCondominiums(guidedAttendancesWithCondo);
        }
      },
    },
  );

  const [remove, { loading: loadingRemove }] = useMutation(
    mutation.disableCondominium,
    {
      onCompleted: () => {
        toast.fire({
          title: t('remove.success'),
          icon: 'success',
        });
        getAll();
      },
      onError: err => {
        const formattedErrors = apolloErrorHandler(err);
        if (formattedErrors && formattedErrors.length) {
          toast.fire({ title: formattedErrors.join('\n'), icon: 'error' });
        }
      },
    },
  );

  const askForRemove = async item => {
    const removeConfirmation = await confirm({
      title: t('remove.modal.title'),
      text: t('remove.modal.text', { condoName: item?.condominium?.name }),
      cancelButtonText: t('buttons:no'),
      confirmButtonText: t('buttons:yes'),
    });

    if (removeConfirmation)
      return remove({
        variables: {
          guidedAttendanceSettings: {
            condominiumPersonContextId: item?.condominium?.id,
            eventType: item.eventType,
          },
        },
      });

    return false;
  };

  const handleRemove = item => askForRemove(item);

  useEffect(() => {
    getAll();
  }, []);

  const handleConcatCondominiumName = item =>
    `${item?.condominium?.id} - ${item?.condominium?.name}`;

  const handleConcatEventName = item => {
    const eventTypeTranslation = t(`event:${item.eventType}`);
    return `${item.eventType} - ${eventTypeTranslation}`;
  };

  return (
    <TableContainer>
      <Table columns={columns}>
        <tbody>
          {!loading &&
            guidedAttendanceCondominiums?.map(item => (
              <tr key={`${item?.condominium?.id}-${item?.eventType}`}>
                <td>{handleConcatCondominiumName(item)}</td>
                <td>{handleConcatEventName(item)}</td>
                <ActionButton>
                  <Button
                    color="secondary"
                    variant="text"
                    size="sm"
                    icon={<FaTrash />}
                    disabled={loadingRemove}
                    onClick={() => handleRemove(item)}
                  />
                </ActionButton>
              </tr>
            ))}
        </tbody>
      </Table>
      {loading && <TableLoader />}
    </TableContainer>
  );
};

export default GuidedAttendanceCondominiums;
