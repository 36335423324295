import { useRef, useState } from 'react';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Flex, Text, TabPane, Nav, NavItem, NavLink } from '@kiper/ui';
import { useFilters } from '@kiper/hooks';
import {
  Container,
  Input,
  SearchCol,
  StyledInput,
  StyledBadge,
  TabContent,
} from './styles';
import { UserGuUnSelect } from '../../Select';
import UsersHosts from './UsersHosts/UsersHosts';
import UsersGuests from './UsersGuests';
import { useCurrentLoggedContext, useRemoteConfig } from '../../../hooks';
import { sendAmplitudeData } from '../../../services/amplitude';

function Users({ event, formik, setAmount, guUnFilter = null }) {
  const [sourceNode, setSourceNode] = useState(null);
  const [activeTab, setActiveTab] = useState('users');

  const [t] = useTranslation('user');

  const [hasGuestsCount, setHasGuestsCount] = useState(false);

  const { loggedContext } = useCurrentLoggedContext();

  const showExpectedGuest = useRemoteConfig({
    path: 'attendance.expectedGuest',
    partner: Number(loggedContext?.partner?.personContextId),
    user: Number(loggedContext?.personContextId),
  });

  const selectRef = useRef();

  const toggleTab = tab => {
    if (tab !== activeTab) {
      setActiveTab(tab);
    }
  };

  const initialFilters = {
    sourceNodeId: '',
    searchText: '',
    page: 1,
    pagesize: 5,
    orderby:
      'unitygroupname, unityname, personcontextparamscallpriority, personname',
    ordination: false,
  };

  const {
    filters,
    handlePageChange,
    handlePageSize,
    handleChange,
    setFilters,
  } = useFilters(initialFilters);

  const handleChangeUnity = item => {
    setSourceNode(item);
    if (item?.treeNodeId)
      return handleChange('sourceNodeId', `${item?.treeNodeId}`);

    return handleChange('sourceNodeId', `${item}`);
  };

  return (
    <Container>
      <SearchCol>
        <Flex>
          <Flex flex={1}>
            <UserGuUnSelect
              onChange={item => handleChangeUnity(item)}
              sourceNodeId={event?.condominium?.personContext?.treeNodeId}
              value={sourceNode}
              selectRef={selectRef}
              guUnFilter={guUnFilter}
            />
          </Flex>
          <Flex flex={1} ml="16px">
            <StyledInput
              data-cy="searchText"
              type="text"
              component={Input}
              name="searchText"
              placeholder={t('list.search-text')}
              value={filters.searchText}
              delay={600}
              onChange={handleChange}
            />
          </Flex>
        </Flex>
      </SearchCol>

      {showExpectedGuest ? (
        <>
          <Flex mb="8px" position="sticky">
            <Nav tabs>
              <NavItem>
                <NavLink
                  active={activeTab === 'users'}
                  onClick={() => {
                    toggleTab('users');
                    sendAmplitudeData('change to users tab', {
                      user: loggedContext?.email,
                      condominium: event?.condominium?.name,
                      partner: loggedContext?.partner?.name,
                    });
                  }}
                >
                  <Flex alignItems="end">
                    <Text
                      ml="4px"
                      color={
                        activeTab === 'users' ? 'primary500' : 'mediumBlack'
                      }
                      fontSize="14px"
                    >
                      {t('list.table-header.residents')}
                    </Text>
                  </Flex>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  active={activeTab === 'invites'}
                  onClick={() => {
                    toggleTab('invites');
                    sendAmplitudeData('change to invites tab', {
                      user: loggedContext?.email,
                      partner: loggedContext?.partner?.name,
                      condominium: event?.condominium?.name,
                    });
                  }}
                >
                  <Flex alignItems="end">
                    <Text
                      ml="4px"
                      color={
                        activeTab === 'invites' ? 'primary500' : 'mediumBlack'
                      }
                      fontSize="14px"
                    >
                      {t('list.table-header.pre-authorized-entry')}
                    </Text>
                  </Flex>

                  {hasGuestsCount && (
                    <Flex ml="4px" height="100%">
                      <StyledBadge />
                    </Flex>
                  )}
                </NavLink>
              </NavItem>
            </Nav>
          </Flex>

          <TabContent activeTab={activeTab}>
            <TabPane tabId="users">
              <UsersHosts
                setAmount={setAmount}
                activeTab={activeTab}
                setSourceNode={setSourceNode}
                event={event}
                formik={formik}
                toggleTab={toggleTab}
                filters={filters}
                setFilters={setFilters}
                handlePageChange={handlePageChange}
                handlePageSize={handlePageSize}
                handleChange={handleChange}
                selectRef={selectRef}
              />
            </TabPane>
            <TabPane tabId="invites">
              <UsersGuests
                event={event}
                formik={formik}
                filters={filters}
                setGuestsCount={setHasGuestsCount}
                handleChange={handleChange}
                toggleTab={toggleTab}
              />
            </TabPane>
          </TabContent>
        </>
      ) : (
        <UsersHosts
          setAmount={setAmount}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          setSourceNode={setSourceNode}
          event={event}
          formik={formik}
          filters={filters}
          toggleTab={toggleTab}
          handlePageChange={handlePageChange}
          handlePageSize={handlePageSize}
          handleChange={handleChange}
          selectRef={selectRef}
        />
      )}
    </Container>
  );
}

export default Users;

Users.propTypes = {
  event: propTypes.object.isRequired,
  formik: propTypes.object.isRequired,
  setAmount: propTypes.func,
  guUnFilter: propTypes.oneOfType([propTypes.object, propTypes.number]),
};

Users.defaultProps = {
  setAmount: () => {},
  guUnFilter: null,
};
