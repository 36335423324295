import { useState } from 'react';
import { useSwal } from '@kiper/hooks';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { uploadFace } from '../../../../services/api/uploadFace';
import {
  useAttendanceRemoteConfig,
  useCurrentLoggedContext,
} from '../../../../hooks';

const usePhotoSubmission = ({ event }) => {
  const [loadingSubmitPhoto, setLoadingSubmitPhoto] = useState(false);
  const [photoSent, setPhotoSent] = useState(false);
  const { t } = useTranslation('guided-attendance');
  const { toast } = useSwal();
  const { loggedContext } = useCurrentLoggedContext();
  const { enableSyncFaceOnUploadImage } = useAttendanceRemoteConfig(
    loggedContext,
    event?.condominium,
  );
  const {
    values,
    setFieldValue,
    setFieldError,
    handleSubmit,
  } = useFormikContext();

  const handleSendPhoto = async photo => {
    setFieldError('stepPhotoRegister.photoRegistered', null);
    const { stepRegisterServiceProvider, stepFindRegister } = values;

    const transientPersonId =
      stepRegisterServiceProvider?.transientPersonId ||
      stepFindRegister?.transientPersonId;

    const data = {
      screenshot: photo,
      transientPersonId,
    };

    setLoadingSubmitPhoto(true);

    try {
      await uploadFace({ data, event, sync: enableSyncFaceOnUploadImage });
      setFieldValue('stepPhotoRegister', {
        photoRegistered: true,
      });
      toast.fire({
        title: t('step-photo-register.success-submit-photo'),
        icon: 'success',
      });
      setPhotoSent(true);
      setTimeout(() => setPhotoSent(false), 2000);
      setLoadingSubmitPhoto(false);
      handleSubmit();
    } catch (err) {
      setLoadingSubmitPhoto(false);
    }
  };

  return {
    loadingSubmitPhoto,
    photoSent,
    handleSendPhoto,
  };
};

export default usePhotoSubmission;
