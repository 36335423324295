import styled, { css } from 'styled-components';
import {
  FormGroup as formgroup,
  Form as form,
  Input as input,
  Row as row,
  Col as col,
} from 'reactstrap';
import {
  Label as label,
  CheckBox as checkbox,
  Box,
  Flex as flex,
} from '@kiper/ui';
import { FiAlertCircle } from 'react-icons/fi';

export const NewTabIcon = styled.span`
  position: absolute;
  right: -18px;
  top: -10px;
  font-size: 10px;
  color: ${props => props.theme.colors.white};
  background-color: ${props => props.theme.colors.danger500};
  border-radius: 16px;
  height: auto;
  padding: 0 6px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SpaceEvenly = styled.div`
  justify-content: space-evenly;
  display: flex;
`;

export const DashedBorder = styled(flex)`
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23C1C1C1FF' stroke-width='3' stroke-dasharray='12%2c 10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
`;

export const Label = styled(label)`
  margin-bottom: 0;
`;

export const Td = styled.td`
  border: none;
  background-color: ${props => props.theme.colors.secondary50};

  tr {
    &:hover {
      background-color: ${props => props.theme.colors.hoverGrey} !important;
    }
  }

  table,
  td,
  tr {
    border: none !important;
  }
`;

export const ContainerEmptyState = styled.div`
  max-width: 80vw;
  padding: 15px;
`;

export const InfoContainer = styled.div`
  label {
    margin-bottom: 15px;
    line-height: 18px;
  }
`;

export const RadioButton = styled.input`
  cursor: pointer;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  border: 1px solid ${({ theme }) => theme.colors.secondary500};
  appearance: none;

  &:checked {
    box-shadow: inset 0 0 0 2px ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.primary500};
    border: 1px solid ${({ theme }) => theme.colors.primary500};
  }
`;

export const RadioButtonWrapper = styled(flex)`
  align-items: center;
  grid-gap: 8px;
`;

export const Input = styled(input)`
  border-color: #b5b5b6;

  ::placeholder {
    line-height: 1.14;
    letter-spacing: 0.5px;
    color: #b5b5b6;
  }
`;

export const Flex = styled.div`
  display: flex;
  ${props =>
    props.justify &&
    css`
      justify-content: ${props.justify};
    `}
  ${props =>
    props.margin &&
    css`
      margin: ${props.margin};
    `}
`;

export const Form = styled(form)`
  display: flex;
  height: 100%;
`;

export const FormGroup = styled(formgroup)`
  width: 100%;
  height: 100%;
`;

export const RadioButtonContainer = styled(flex)`
  gap: 8px;
  align-items: center;
`;

export const RadioGroup = styled(Flex)`
  align-items: center;
  grid-gap: 12px;
`;

export const ModalActions = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const TooltipContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const CheckBoxContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

export const CheckBoxContent = styled.div`
  display: flex;
  align-items: center;

  label {
    margin: 0 0 0 5px;
  }
`;

export const Row = styled(row)``;

export const Col = styled(col)``;

export const AlertIcon = styled(FiAlertCircle)``;

export const CheckBox = styled(checkbox)`
  width: 16px;
  height: 16px;
`;

export const Alert = styled.div`
  display: flex;
  align-items: center;
  background-color: ${props =>
    props.info ? props.theme.colors.info100 : props.theme.colors.secondary100};
  color: ${props => props.theme.colors.highBlack};
  border-radius: 4px;
  padding: 10px 10px 10px 10px;
  margin-bottom: 10px;

  label {
    margin-bottom: 0;
  }
`;

export const AlertIconContainer = styled.div`
  min-width: 40px;
  display: flex;
  justify-content: start;
  align-items: center;
`;

export const RadioLabel = styled(label)`
  margin-bottom: 0;
`;

export const AddMoreContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  border: 1px dashed #b5b5b6;
  box-sizing: border-box;
  border-radius: 8px;
`;

export const BadgeLabel = styled(Label)`
  color: ${props => props.theme.colors.white};
`;
