import { useMemo } from 'react';
import { useQuery } from 'react-apollo';
import * as MdIcon from 'react-icons/md';
import { queries } from '@kiper/monitoring-graphql/triage';
import { useGetEventDeviceWay, useGuidedAttendance } from '../../../../hooks';
import { triageIds } from '../../../../constants';

const triageType = Object.freeze({
  LEGACY: 'legacy',
  NEW: 'new',
  LEGACY_AND_NEW: 'legacyAndNew',
});

const useQueries = ({ event }) => {
  const { handleValidHasSubtriage } = useGuidedAttendance();
  const { entryDirection } = useGetEventDeviceWay(event);

  const hasSubtriage = useMemo(() => {
    return handleValidHasSubtriage(event?.condominium);
  }, [event]);

  const { data, loading } = useQuery(queries.triages, {
    fetchPolicy: 'network-only',
  });

  const handleFilterTriage = triage => {
    if (entryDirection && +triage.id === triageIds.exit) return false;

    const { serializedParams } = triage;
    if (!serializedParams) return false;

    const params = JSON.parse(serializedParams);

    const isLegacyAndNew = params?.triageType === triageType.LEGACY_AND_NEW;
    const isLegacy = params?.triageType === triageType.LEGACY;
    const isNew = params?.triageType === triageType.NEW;

    if (hasSubtriage) return isLegacyAndNew || isNew;

    return isLegacyAndNew || isLegacy;
  };

  const triages = useMemo(() => {
    return (
      data?.triages
        ?.filter(triage => handleFilterTriage(triage))
        ?.map(triage => {
          if (triage?.icon) {
            const RenderIcon = MdIcon[triage.icon];
            return {
              ...triage,
              id: +triage.id,
              icon: <RenderIcon />,
            };
          }
          return { ...triage, id: +triage.id };
        }) || []
    );
  }, [data]);

  return { triages, loading };
};

export default useQueries;
