import { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { sendAmplitudeData } from '../../../../../services/amplitude';
import { useCondominiumRemoteConfig } from '../../../../../hooks';
import { tabIds, useCondominiumDetails } from '../../CondominiumDetailProvider';

export const useTabChange = (loggedContext, condominium, t, S) => {
  const { tab: pageTabId, id: condominiumId } = useParams();
  const history = useHistory();

  const [activeTab, setActiveTab] = useState(tabIds.GENERAL_DATA);

  useEffect(() => {
    if (pageTabId) {
      setActiveTab(pageTabId);
    }
  }, [pageTabId]);

  const {
    licenses: { hasVideoCallLicense, hasRemoteConciergeLicense },
  } = useCondominiumDetails();

  const {
    showAttendanceSettings,
    showApplicationParameters,
    showActivityManagement,
  } = useCondominiumRemoteConfig(loggedContext, condominium);

  const tabs = [
    {
      title: t(`details.${tabIds.GENERAL_DATA}`),
      tabId: tabIds.GENERAL_DATA,
      showTab: true,
    },
    {
      title: t(`details.${tabIds.UNITY_CONFIGURATION}.title`),
      tabId: tabIds.UNITY_CONFIGURATION,
      showTab: true,
    },
    {
      title: t(`details.${tabIds.ATTENDANCE_SETTINGS}.title`),
      tabId: tabIds.ATTENDANCE_SETTINGS,
      showTab: showAttendanceSettings,
    },
    {
      title: t(`details.${tabIds.APPLICATION_PARAMETERS}.tab-title`),
      tabId: tabIds.APPLICATION_PARAMETERS,
      showTab: showApplicationParameters,
    },
    {
      title: t(`details.${tabIds.ACTIVITY_MANAGEMENT}.title`),
      tabId: tabIds.ACTIVITY_MANAGEMENT,
      showTab: showActivityManagement,
    },
    {
      title: t(`details.${tabIds.SIP_CONFIGURATION}.title`),
      tabId: tabIds.SIP_CONFIGURATION,
      showTab: hasRemoteConciergeLicense || hasVideoCallLicense,
      icon: <S.NewTabIcon>{t(`details.new`)}</S.NewTabIcon>,
    },
    {
      title: t(`details.${tabIds.CONDOMINIUM_RULES}.tab-title`),
      tabId: tabIds.CONDOMINIUM_RULES,
      showTab: true,
    },
    {
      title: t(`details.${tabIds.EXPORTATIONS}.title`),
      tabId: tabIds.EXPORTATIONS,
      showTab: true,
      icon: <S.NewTabIcon>{t(`details.new`)}</S.NewTabIcon>,
    },
  ];

  const handleChangeTab = tab => {
    history.push(`/condominiums/${condominiumId}/${tab}`);
    if (tab !== activeTab) {
      setActiveTab(tab);
      sendAmplitudeData(`change to condominium tab -> ${tab}`, {
        user: loggedContext?.email,
        condominium: condominium?.name,
        partner: loggedContext?.partner?.name,
      });
    }
  };

  return { activeTab, handleChangeTab, tabs };
};
