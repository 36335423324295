import { useContext } from 'react';
import { PlacesContext } from '../store';

const useGetPlaces = () => {
  const context = useContext(PlacesContext);

  return context;
};

export default useGetPlaces;
