import { useMemo } from 'react';
import { facialDirectionEnum } from '../constants';

const useGetEventDeviceWay = event => {
  const { entryDirection, exitDirection } = useMemo(
    () => ({
      entryDirection: event?.device?.serializedParams
        ? JSON.parse(event.device.serializedParams)?.way ===
          facialDirectionEnum.Entry
        : null,
      exitDirection: event?.device?.serializedParams
        ? JSON.parse(event.device.serializedParams)?.way ===
          facialDirectionEnum.Exit
        : null,
    }),
    [event],
  );

  return { entryDirection, exitDirection };
};

export default useGetEventDeviceWay;
