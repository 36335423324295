import { useEffect } from 'react';
import propTypes from 'prop-types';
import { useQuery } from 'react-apollo';
import { Label, Select } from '@kiper/ui';
import { placeTypeLabel as placeTypeLabelGql } from '@kiper/monitoring-graphql';

export default function PlaceTypeLabelSelect({
  isClearable,
  invalid,
  value,
  isEditable,
  personContextId,
  fieldName,
  onChange,
  ...props
}) {
  const { data, loading } = useQuery(
    placeTypeLabelGql.queries.placeTypeLabelsSelect,
    {
      skip: !personContextId,
      variables: {
        personContextId,
        fieldName,
      },
    },
  );

  const getValue = () => {
    if (!value && !data?.placeTypeLabelsSelect?.length) return '';

    if (data?.placeTypeLabelsSelect?.length === 1)
      return data?.placeTypeLabelsSelect[0];

    return data?.placeTypeLabelsSelect?.find(p => +p?.value === +value?.value);
  };

  useEffect(() => {
    if (typeof onChange === 'function') {
      onChange(getValue());
    }
  }, [data]);

  if (!isEditable) return <Label>{value && value.label}</Label>;

  return (
    <Select
      {...props}
      value={getValue()}
      isClearable={isClearable}
      isLoading={loading}
      isDisabled={!personContextId}
      options={(data && data.placeTypeLabelsSelect) || []}
      invalid={Number(invalid)}
      onChange={onChange}
    />
  );
}

PlaceTypeLabelSelect.propTypes = {
  value: propTypes.any,
  isClearable: propTypes.bool,
  invalid: propTypes.bool,
  isEditable: propTypes.bool,
  personContextId: propTypes.number,
  fieldName: propTypes.string.isRequired,
  onChange: propTypes.func.isRequired,
};

PlaceTypeLabelSelect.defaultProps = {
  isClearable: false,
  invalid: false,
  isEditable: true,
  value: null,
  personContextId: undefined,
};
