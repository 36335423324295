import { Flex, Text as text } from '@kiper/ui';
import styled from 'styled-components';

export const YesOrNoContainer = styled(Flex)`
  gap: 4px;
  justify-content: space-between;
  align-items: center;
`;

export const TextContainer = styled(Flex)`
  gap: 4px;
  flex-direction: column;
`;

export const ScheduleContainer = styled(Flex)`
  gap: 4px;
  flex-direction: column;
`;

export const Text = styled(text)`
  font-size: 12px;
  font-weight: ${props => (props.weight ? props.weight : 800)};
  color: ${props =>
    props.color
      ? props.theme.colors[props.color]
      : props.theme.colors.highBlack};
  white-space: break-spaces;

  @media screen and (min-width: 1600px) {
    font-size: 14px;
  }
`;

export const Badge = styled.div`
  padding: 2px 16px;
  border-radius: 16px;
  background-color: ${props => props.theme.colors[props.color]};
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
`;
