import { useContext, Fragment } from 'react';
import propTypes from 'prop-types';
import { Spinner } from '@kiper/ui';
import { useGetDescriptions, useQueries, useQuestionAnswerType } from './hooks';
import { CondominiumRuleFilterContext } from '../../../../../store';
import { AwaitingSubtriage, RuleAdjustmentLink } from './components';
import {
  Container,
  Description,
  HeaderTitle,
  LoadingWrapper,
  Wrapper,
  CondominiumRuleContainer,
} from './styles';
import useSelectedRuleReview from './hooks/useSelectedRuleReview';
import useRegisterActivity from './hooks/useRegisterActivity';
import {
  useAttendanceRemoteConfig,
  useCurrentLoggedContext,
} from '../../../../../hooks';

const QuestionsAndAnswers = ({ condominiumPersonContextId, event, t }) => {
  const {
    selectedTriage,
    selectedSubtriage,
    questions,
    loading,
    filteredTriage,
    filteredSubtriage,
  } = useContext(CondominiumRuleFilterContext);

  useQueries({
    condominiumPersonContextId,
    eventId: event?.eventId,
  });

  const {
    visibleRuleReview,
    setVisibleRuleReview,
    handleVisibleRuleReview,
  } = useSelectedRuleReview();

  const { loggedContext } = useCurrentLoggedContext();

  const { showRuleReview } = useAttendanceRemoteConfig(
    loggedContext,
    event?.condominium,
  );
  const { handleQuestionAnswerType } = useQuestionAnswerType();

  const { handleGetDescription } = useGetDescriptions();

  const triageDescription = handleGetDescription(
    filteredTriage || selectedTriage,
  );

  const subtriageDescription = handleGetDescription(
    filteredSubtriage || selectedSubtriage,
  );

  const { handleRegisterRuleReviewActivity } = useRegisterActivity(
    loading,
    setVisibleRuleReview,
    t,
  );

  if (!loading && !filteredSubtriage) {
    return <AwaitingSubtriage t={t} />;
  }

  return (
    <Container>
      {triageDescription && subtriageDescription && (
        <Wrapper>
          <HeaderTitle>{t('attendance')}</HeaderTitle>
          <Description>{`${triageDescription} - ${subtriageDescription}`}</Description>
        </Wrapper>
      )}

      {loading && (
        <LoadingWrapper>
          <Spinner size={28} />
        </LoadingWrapper>
      )}

      {!loading &&
        !!questions?.length &&
        questions?.map(question => (
          <Fragment key={question.id}>
            <CondominiumRuleContainer
              className={visibleRuleReview === question.id ? 'clicked' : ''}
              onClick={e =>
                showRuleReview && handleVisibleRuleReview(e, question.id)
              }
              questionAnswerVariant={question.answer.questionAnswerType}
            >
              {handleQuestionAnswerType(question)}
              {showRuleReview && (
                <RuleAdjustmentLink
                  id={question.id}
                  className={visibleRuleReview === question.id ? 'visible' : ''}
                  message={t('adjustment-message')}
                  onClick={e =>
                    handleRegisterRuleReviewActivity(
                      e,
                      event,
                      question,
                      triageDescription,
                      subtriageDescription,
                    )
                  }
                />
              )}
            </CondominiumRuleContainer>
          </Fragment>
        ))}
    </Container>
  );
};

export default QuestionsAndAnswers;

QuestionsAndAnswers.propTypes = {
  subtriage: propTypes.shape({
    id: propTypes.number,
    description: propTypes.string,
    descriptionEs: propTypes.string,
    descriptionEn: propTypes.string,
  }),
  triage: propTypes.shape({
    id: propTypes.number,
    description: propTypes.string,
    descriptionEs: propTypes.string,
    descriptionEn: propTypes.string,
  }),
  condominiumPersonContextId: propTypes.number.isRequired,
  t: propTypes.func.isRequired,
  event: propTypes.object.isRequired,
};

QuestionsAndAnswers.defaultProps = {
  subtriage: null,
  triage: null,
};
