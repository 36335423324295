import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Form } from 'reactstrap';
import {
  Button,
  ErrorMessage,
  Flex,
  Input,
  Label,
  Modal,
  Select,
} from '@kiper/ui';
import {
  useAccessProfileSelection,
  useCommercialLicense,
  useCondominiumCommercialUnity,
  useUnityForm,
} from './hooks';
import PlaceTypeLabelSelect from '../../PlaceTypeLabelSelect';
import AccessProfileSelect from '../../AccessProfileSelect';
import { CheckBox, InputNumber } from './styles';

const translate = sufix => `details.modal.unity.${sufix}`;
const UnityModal = ({
  condominium,
  handleToggle,
  unity,
  unities,
  unityGroupId,
}) => {
  const { t } = useTranslation('condominium');

  const {
    hasCommercialLicense,
    commercialPersonContextId,
  } = useCommercialLicense(condominium);

  const {
    values,
    setFieldValue,
    touched,
    errors,
    handleSubmit,
    dirty,
    isSubmitting,
  } = useUnityForm({
    unity,
    unities,
    unityGroupId,
    commercialPersonContextId,
    handleToggle,
    t,
  });

  const {
    commercialUnitsOptions,
    getCommercialUnityValue,
    loadingCommercialUnits,
  } = useCondominiumCommercialUnity({
    hasCommercialLicense,
    condominiumCommercialId: condominium?.personContextId,
  });

  const {
    ableSelectAccessProfileToUnity,
    handleCheckAbleSelectAccessProfileToUnity,
  } = useAccessProfileSelection(unity, values, setFieldValue);

  const handleOnlyNumber = event => {
    if ([69, 107, 109, 187, 188, 189, 190, 229].includes(event?.which)) {
      event.preventDefault();
    }
  };

  return (
    <Modal
      title={t(translate('title'), {
        action: unity?.id ? t(translate('edit')) : t(translate('add')),
      })}
      open
      toggle={handleToggle}
      size="md"
    >
      <Form onSubmit={handleSubmit}>
        <Flex flexDirection="column" gridGap="16px">
          {hasCommercialLicense && (
            <Flex flexDirection="column">
              <Label info for="name" block>
                {t(translate('commercial-unity'))}
              </Label>
              <Select
                placeholder={t(translate('commercial-unity-placeholder'))}
                options={commercialUnitsOptions}
                disabled={loadingCommercialUnits}
                value={getCommercialUnityValue(
                  values?.condominiumCommercialUnity?.value,
                )}
                isLoading={loadingCommercialUnits}
                onChange={e => setFieldValue('condominiumCommercialUnity', e)}
                isClearable
                width="100%"
              />
            </Flex>
          )}
          <Flex flexDirection="column">
            <Label info block>
              {t(translate('type-select'))}
            </Label>

            <PlaceTypeLabelSelect
              isEditable
              name="placeTypeLabel"
              classNamePrefix="unity-type"
              fieldName="unity"
              personContextId={+condominium?.personContextId}
              placeholder={t(translate('type-select'))}
              value={values?.placeTypeLabel || ''}
              invalid={
                !!errors?.placeTypeLabel || !!errors?.placeTypeLabel?.value
              }
              width="100%"
              onChange={e => setFieldValue('placeTypeLabel', e)}
            />
            {(!!errors?.placeTypeLabel || !!errors?.placeTypeLabel?.value) && (
              <ErrorMessage>{t('common:feedback.required-field')}</ErrorMessage>
            )}
          </Flex>
          <Flex flexDirection="column">
            <Label info for="name" block>
              {t(translate('name-input-label'))}
            </Label>
            <Input
              type="text"
              name="name"
              className="form-control"
              placeholder={t(translate('name-placeholder'))}
              value={values.name}
              onChange={setFieldValue}
              invalid={!!errors.name}
            />
            {!!errors.name && <ErrorMessage>{errors?.name}</ErrorMessage>}
          </Flex>

          <Flex flexDirection="column" flex="1">
            <Label info for="extension" block>
              {t(translate('extension-input-label'))}
            </Label>
            <InputNumber
              type="number"
              placeholder={t('common:feedback.only-numbers')}
              name="serializedParams.extension"
              onChange={setFieldValue}
              onKeyDown={handleOnlyNumber}
              value={values?.serializedParams?.extension}
              invalid={
                errors?.serializedParams?.extension &&
                touched?.serializedParams?.extension
              }
            />
            {touched?.serializedParams?.extension &&
              !!errors?.serializedParams?.extension && (
                <ErrorMessage>
                  {errors?.serializedParams?.extension}
                </ErrorMessage>
              )}
          </Flex>
          <Flex alignItems="center" gridGap="8px">
            <CheckBox
              id="checkbox-define-default-access-profile-to-unity"
              type="radio"
              name="ableSelectAccessProfileToUnity"
              checked={ableSelectAccessProfileToUnity}
              onChange={handleCheckAbleSelectAccessProfileToUnity}
            />
            <Label
              $withoutMarginBottom
              isCheckboxLabel
              for="checkbox-define-default-access-profile-to-unity"
            >
              {t(translate('checkbox-define-default-access-profile-to-unity'))}
            </Label>
          </Flex>

          <Flex flexDirection="column" flex="1">
            <Label info for="serializedParams.defaultAccessProfile" block>
              {t(translate('default-access-profile-select-label'))}
            </Label>
            <AccessProfileSelect
              isEdition={!!unity?.id}
              name="defaultAccessProfile"
              width="100%"
              classNamePrefix="select-profile"
              sourceNodeId={condominium?.treeNodeId}
              placeholder={t(
                translate('select-default-access-profile-placeholder'),
              )}
              onChange={e =>
                setFieldValue('defaultAccessProfile', {
                  label: (e && e.label) || '',
                  value: (e && e.value) || '',
                })
              }
              value={values?.defaultAccessProfile || ''}
              invalid={
                !!errors?.defaultAccessProfile &&
                !!touched?.defaultAccessProfile
              }
              isDisabled={ableSelectAccessProfileToUnity}
            />
            {!!errors?.defaultAccessProfile &&
              !!touched?.defaultAccessProfile && (
                <ErrorMessage>{errors?.defaultAccessProfile}</ErrorMessage>
              )}
          </Flex>

          <Flex justifyContent="flex-end" gridGap="8px">
            <Button
              color="secondary"
              outline
              type="button"
              onClick={() => handleToggle()}
            >
              {t('buttons:cancel')}
            </Button>
            <Button
              type="submit"
              color="primary"
              loading={isSubmitting}
              disabled={isSubmitting || !dirty}
            >
              {isSubmitting ? t('buttons:saving') : t('buttons:save')}
            </Button>
          </Flex>
        </Flex>
      </Form>
    </Modal>
  );
};

export default UnityModal;

UnityModal.propTypes = {
  condominium: propTypes.object.isRequired,
  handleToggle: propTypes.func.isRequired,
  unity: propTypes.object.isRequired,
  unities: propTypes.array.isRequired,
  unityGroupId: propTypes.number.isRequired,
};
