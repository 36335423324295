import styled from 'styled-components';
import { Flex, Text } from '@kiper/ui';
import { questionAnswerType } from '../../../../../constants';

export const Container = styled(Flex)`
  gap: 4px;
  flex-direction: column;
  padding: 8px;
  width: 100%;
  height: 100%;
`;

export const LoadingWrapper = styled(Flex)`
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

export const Wrapper = styled(Flex)`
  flex-direction: column;
  flex-wrap: wrap;
`;

export const HeaderTitle = styled(Text)`
  font-family: 'Nunito Sans', sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
  width: max-content;
  color: ${({ theme }) => theme.colors.blackShades(0.8)};
  opacity: 0.9;
`;

export const Description = styled(Text)`
  font-family: 'Nunito Sans', sans-serif;
  font-size: 12px;
  font-weight: 800;
  color: ${({ theme }) => theme.colors.highBlack};
`;

const handlerQuestionAnswerVariant = (questionAnswerVariant, theme) => {
  const variants = {
    [questionAnswerType.YES_NO]: {
      backgroundColor: theme.colors.warning50,
      hoverBackgroundColor: theme.colors.warning100,
    },
    [questionAnswerType.TEXT]: {
      backgroundColor: theme.colors.info100,
      hoverBackgroundColor: theme.colors.info150,
    },
    [questionAnswerType.SCHEDULE]: {
      backgroundColor: theme.colors.purple100,
      hoverBackgroundColor: theme.colors.purple150,
    },
  };

  const defaultVariant = {
    backgroundColor: theme.colors.info100,
    hoverBackgroundColor: theme.colors.info150,
  };

  return variants[questionAnswerVariant] || defaultVariant;
};

export const CondominiumRuleContainer = styled(Flex)`
  gap: 4px;
  padding: 4px 8px;
  border-radius: 8px;
  flex-direction: column;

  cursor: pointer;
  &.clicked {
    cursor: default;
  }

  background-color: ${props =>
    handlerQuestionAnswerVariant(props.questionAnswerVariant, props.theme)
      .backgroundColor};
  &:hover {
    background-color: ${props =>
      handlerQuestionAnswerVariant(props.questionAnswerVariant, props.theme)
        .hoverBackgroundColor};
  }
`;

export const RuleAdjustment = styled(Flex)`
  gap: 4px;
  align-items: center;
  color: ${props => props.theme.colors.blue900};
  transition: all 0.1s ease-in-out;

  width: fit-content;
  max-height: 0;
  font-size: 12px;
  overflow: hidden;
  cursor: pointer;

  &.visible {
    max-height: 15px;
  }
`;
