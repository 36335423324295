import { useContext, useEffect } from 'react';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Flex, PlaceHolder } from '@kiper/ui';
import StepStructure, { StepTextTypeColor } from '../StepStructure';
import { GuidedAttendanceContext } from '../../../store';
import { useGetPlaces } from './hooks';
import { useDeviceStatus, useMaxDimensions } from '../../../hooks';
import { EventActions, PlaceInformation, PlaceItem } from './components';
import { PlacesProvider } from './store';
import { Container } from './styles';

const heightToBeDiscounted = 48;

const loaderStyle = {
  height: '39px',
  width: '100%',
  marginBottom: '8px',
  borderRadius: '4px',
};

const StepOpenDoorsWrapper = ({ event }) => {
  return (
    <PlacesProvider event={event}>
      <StepOpenDoors event={event} />
    </PlacesProvider>
  );
};

const StepOpenDoors = ({ event }) => {
  const { t } = useTranslation('guided-attendance');
  const { setContextTree } = useContext(GuidedAttendanceContext);
  const { maxHeight, elementRef } = useMaxDimensions({ heightToBeDiscounted });
  const { places, loading } = useGetPlaces();
  const { deviceStatus } = useDeviceStatus({ places });

  useEffect(() => {
    setContextTree({
      hideAttendanceDetailInfo: true,
    });

    return () => {
      setContextTree({
        hideAttendanceDetailInfo: false,
      });
    };
  }, []);

  return (
    <Flex width="100%" flexDirection="column">
      <Flex flex={1} width="100%" flexDirection="row" pb="8px">
        <Flex width="100%" flexDirection="column">
          <StepStructure
            type={StepTextTypeColor.OPEN_DOORS}
            text={t('step-open-doors.title')}
            hasPreviousStepButton
          >
            <Container maxheight={maxHeight} ref={elementRef}>
              {loading && !places?.length && (
                <PlaceHolder style={loaderStyle} type="line" lines={8} />
              )}
              {places?.map(place => (
                <PlaceItem
                  key={place.id}
                  place={place}
                  event={event}
                  deviceStatus={deviceStatus}
                />
              ))}
            </Container>
          </StepStructure>
        </Flex>
        <PlaceInformation event={event} deviceStatus={deviceStatus} />
      </Flex>
      <EventActions event={event} />
    </Flex>
  );
};

export default StepOpenDoorsWrapper;

StepOpenDoors.propTypes = {
  event: propTypes.object.isRequired,
};

StepOpenDoorsWrapper.propTypes = {
  event: propTypes.object.isRequired,
};
