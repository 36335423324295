import { useFormikContext } from 'formik';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect } from 'react';
import { useGuidedAttendance, useInsertStepHistory } from '../../../../hooks';
import { GuidedAttendanceContext } from '../../../../store';

const useForm = eventId => {
  const { handleInsertStepHistory } = useInsertStepHistory();
  const { t } = useTranslation('guided-attendance');
  const { handleNextAction } = useGuidedAttendance();
  const { setContextTree } = useContext(GuidedAttendanceContext);

  const { values, setFieldValue } = useFormikContext();

  const schema = yup.object({
    stepPhotoRegister: yup.object({
      photoRegistered: yup.boolean().when('cameraUnavailable', {
        is: cameraUnavailable => !cameraUnavailable,
        then: yup
          .boolean()
          .oneOf([true], t('step-photo-register.feedback-error')),
      }),
      cameraUnavailable: yup.boolean(),
    }),
  });

  const submitCurrentStepHistory = steps => {
    const { stepPhotoRegister } = steps;
    if (stepPhotoRegister?.photoRegistered) {
      const data = {
        photoRegistered: true,
      };

      handleInsertStepHistory(data, eventId);
    }
  };

  const nextAction = async stepData => {
    submitCurrentStepHistory(stepData);

    const nextStep = await handleNextAction({
      values: stepData,
      fieldValue: 'stepPhotoRegister',
    });
    return { nextStep, currentStepData: stepData };
  };

  useEffect(() => {
    if (!values?.stepPhotoRegister?.photoRegistered) {
      setFieldValue('stepPhotoRegister', {
        photoRegistered: false,
        cameraUnavailable: false,
      });
    }

    setContextTree({
      formikSchema: schema,
      nextAction,
    });
  }, []);

  return { values };
};

export default useForm;
