import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Flex, Text } from '@kiper/ui';
import PlaceCamera from '../PlaceCamera';
import useMaxDimensions from '../../../../../hooks/useMaxDimensions';
import CondominiumMap from '../CondominiumMap';
import { useGetPlaces } from '../../hooks';
import { Container } from './styles';

const heightToBeDiscounted = 48;
const PlaceInformation = ({ event, deviceStatus }) => {
  const { maxHeight, elementRef } = useMaxDimensions({ heightToBeDiscounted });
  const { t } = useTranslation('guided-attendance');
  const { selectedPlace } = useGetPlaces();

  return (
    <Flex
      maxWidth="518px"
      width="100%"
      flexDirection="column"
      pl="8px"
      gridGap="8px"
      mt="-8px"
    >
      <Text fontWeight="bolder">
        {t(`step-open-doors.location-information`, {
          name: selectedPlace?.name,
        })}
      </Text>
      <Container ref={elementRef} maxheight={maxHeight}>
        <PlaceCamera event={event} />
        <CondominiumMap event={event} deviceStatus={deviceStatus} />
      </Container>
    </Flex>
  );
};

export default PlaceInformation;

PlaceInformation.propTypes = {
  event: propTypes.object.isRequired,
  deviceStatus: propTypes.array.isRequired,
};
