import styled from 'styled-components';

export const Actions = styled.div`
  position: absolute;
  right: 10px;
  display: flex;
  background: ${props => props.theme.colors.white};
  padding-top: 10px;
  padding-bottom: 10px;

  &::before {
    -webkit-transition: all 0.1s;
    transition: all 0.1s;
    content: '';
    width: 30px;
    margin-left: -30px;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;

    background-image: linear-gradient(90deg, transparent 0%, #fff 100%);
  }
`;

export const Td = styled.td`
  cursor: pointer;

  &:hover {
    span.hostname {
      color: ${props => props.theme.colors.link};
      transform: scale(1.05);
      text-decoration: underline;
    }
  }
`;
