import { useEffect, useState } from 'react';
import { useWindowDimensions } from '@kiper/hooks';

const MEDIA_ASPECT_RATIO_CONFIG = {
  HD: {
    CAPTURED_PHOTO: {
      width: 172,
      height: 262,
      cropWidth: 350,
    },
    VIDEO_STREAM: {
      width: 466,
      height: 262,
    },
  },
  FULL_HD: {
    CAPTURED_PHOTO: {
      width: 240,
      height: 360,
      cropWidth: 480,
    },
    VIDEO_STREAM: {
      width: 640,
      height: 360,
    },
  },
};
const useMediaAspectRatio = () => {
  const { width: windowsWidth } = useWindowDimensions();
  const [videoAspectRatio, setVideoAspectRatio] = useState({
    width: MEDIA_ASPECT_RATIO_CONFIG.FULL_HD.VIDEO_STREAM.width,
    height: MEDIA_ASPECT_RATIO_CONFIG.FULL_HD.VIDEO_STREAM.height,
  });
  const [capturedPhotoAspectRatio, setCapturedPhotoAspectRatio] = useState({
    width: MEDIA_ASPECT_RATIO_CONFIG.FULL_HD.CAPTURED_PHOTO.width,
    height: MEDIA_ASPECT_RATIO_CONFIG.FULL_HD.CAPTURED_PHOTO.height,
  });

  useEffect(() => {
    if (windowsWidth <= 1600) {
      setVideoAspectRatio({
        width: MEDIA_ASPECT_RATIO_CONFIG.HD.VIDEO_STREAM.width,
        height: MEDIA_ASPECT_RATIO_CONFIG.HD.VIDEO_STREAM.height,
      });
      setCapturedPhotoAspectRatio({
        width: MEDIA_ASPECT_RATIO_CONFIG.HD.CAPTURED_PHOTO.width,
        height: MEDIA_ASPECT_RATIO_CONFIG.HD.CAPTURED_PHOTO.height,
      });
    }
  }, [windowsWidth]);

  return { videoAspectRatio, capturedPhotoAspectRatio };
};

export default useMediaAspectRatio;
