import propTypes from 'prop-types';
import { colors } from '@kiper/theme';
import { MdOutlineInfo } from 'react-icons/md';
import { RuleAdjustment } from '../styles';

const RuleAdjustmentLink = ({ message, ...props }) => {
  return (
    <RuleAdjustment {...props}>
      <MdOutlineInfo color={colors.blue900} size={16} />
      {message}
    </RuleAdjustment>
  );
};

export default RuleAdjustmentLink;

RuleAdjustmentLink.propTypes = {
  message: propTypes.string.isRequired,
};
