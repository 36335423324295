import propTypes from 'prop-types';
import { Button, Flex } from '@kiper/ui';

const HeaderButtons = ({ history, t }) => {
  return (
    <Flex gridGap="8px">
      <Button
        size="sm"
        onClick={() =>
          history.push('/guided-attendance-settings/enable-attendant')
        }
      >
        {t('list.actions.enable-attendant')}
      </Button>
      <Button
        size="sm"
        onClick={() =>
          history.push('/guided-attendance-settings/enable-condominium')
        }
      >
        {t('list.actions.enable-condominium')}
      </Button>
      <Button
        size="sm"
        onClick={() =>
          history.push('/guided-attendance-settings/enable-partner')
        }
      >
        {t('list.actions.enable-partner')}
      </Button>
    </Flex>
  );
};

export default HeaderButtons;

HeaderButtons.propTypes = {
  history: propTypes.object.isRequired,
  t: propTypes.func.isRequired,
};
