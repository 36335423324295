import { useState } from 'react';

const useSelectedRuleReview = () => {
  const [visibleRuleReview, setVisibleRuleReview] = useState(null);

  const handleVisibleRuleReview = (e, id) => {
    e.stopPropagation();
    setVisibleRuleReview(visibleRuleReview === id ? null : id);
  };

  return {
    visibleRuleReview,
    setVisibleRuleReview,
    handleVisibleRuleReview,
  };
};

export default useSelectedRuleReview;
