import { useMemo } from 'react';
import { getCondominiumParam } from '../helpers';
import { condominiumParamsTypes } from '../constants';

/**
 * Hook personalizado para verificar licenças ERP de um condomínio
 * @param {Object} params - Parâmetros do hook
 * @param {Object} params.condominium - Objeto do condomínio
 * @param {Array<string>} [params.licenseTypes=[]] - Tipos de licença para verificar
 * @returns {Array<boolean>} Array de booleanos na mesma ordem dos `licenseTypes`
 */
const useErpLicenses = ({ condominium, licenseTypes = [] }) => {
  return useMemo(() => {
    const [erpParam] = getCondominiumParam({
      paramsList: condominium.params,
      paramName: condominiumParamsTypes.ERP_LICENSE,
    });

    if (!erpParam?.value) {
      return new Array(licenseTypes.length).fill(false);
    }

    try {
      const parsedValue = JSON.parse(erpParam.value);
      const licenses = parsedValue.licenses || [];

      return licenseTypes.map(licenseType =>
        licenses.some(license => license.licenseType === licenseType),
      );
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error parsing ERP licenses:', error);
      return new Array(licenseTypes.length).fill(false);
    }
  }, [condominium, licenseTypes]);
};

export default useErpLicenses;
