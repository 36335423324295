import { useRef, useCallback, useState, Fragment } from 'react';
import { useMutation } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import { useFilters, useSwal } from '@kiper/hooks';
import { FaTrash } from 'react-icons/fa';
import { apolloErrorHandler } from '@kiper/fns';
import {
  query,
  mutation,
} from '@kiper/monitoring-graphql/guided_attendance_settings';

import { Button } from '@kiper/ui';
import { useWindowDimension } from '../../hooks';
import { InfiniteScrollTable } from '../../../../components/InfiniteScroll';
import { ActionButton } from './styles';

const initialFilters = {
  search: '',
  page: 1,
  pagesize: 10,
};

const GuidedAttendancePartners = () => {
  const { toast, confirm } = useSwal();
  const { t } = useTranslation('guided-attendance-settings');
  const { maxHeightDropDown, tableRef } = useWindowDimension();
  const { filters } = useFilters(initialFilters);
  const [excludedPartners, setExcludedPartners] = useState([]);

  const columns = useRef([
    {
      name: t('list.partner.table-columns.partner'),
      align: 'left',
    },
    {
      name: t('list.partner.table-columns.event-type'),
      align: 'left',
    },
    {
      name: t('list.partner.table-columns.attendance-step-id'),
      align: 'left',
    },
    {
      name: t('list.partner.table-columns.actions'),
      align: 'right',
    },
  ]);

  const [remove, { loading: loadingRemove }] = useMutation(
    mutation.deleteGuidedAttendancePartner,
    {
      onCompleted: () => {
        toast.fire({
          title: t('remove.partner.success'),
          icon: 'success',
        });
      },

      onError: err => {
        const formattedErrors = apolloErrorHandler(err);
        if (formattedErrors && formattedErrors.length) {
          toast.fire({ title: formattedErrors.join('\n'), icon: 'error' });
        }
      },
    },
  );

  const askForRemove = async item => {
    const removeConfirmation = await confirm({
      title: t('remove.partner.modal.title'),
      text: t('remove.partner.modal.text', {
        partnerName: item?.partner?.name,
      }),
      cancelButtonText: t('buttons:no'),
      confirmButtonText: t('buttons:yes'),
    });

    if (removeConfirmation) {
      return remove({
        variables: {
          partnerPersonContextId: item?.partner?.personContextId,
          eventType: item.eventType,
        },
      }).finally(() => {
        setExcludedPartners(prevState => [
          ...prevState,
          item?.partner?.personContextId,
        ]);
      });
    }

    return false;
  };

  const handleRemove = item => askForRemove(item);

  const handleGetQueryVariables = useCallback(() => {
    if (!filters) return null;
    const variables = {
      filters,
    };
    return variables;
  }, [filters]);

  const handleConcatPartnerName = item =>
    `${item?.partner?.personContextId} - ${item?.partner?.name}`;

  const handleConcatEventName = item => {
    const eventTypeTranslation = t(`event:${item.eventType}`);
    return `${item.eventType} - ${eventTypeTranslation}`;
  };

  return (
    <InfiniteScrollTable
      tableRef={tableRef}
      columns={columns}
      variables={handleGetQueryVariables()}
      query={query.getGuidedAttendancePartners}
      tableMaxHeight={maxHeightDropDown}
      hover
      renderListItem={({ item, trRef }) => (
        <Fragment key={`${item?.partner?.personContextId}-${item.eventType}`}>
          {!excludedPartners.includes(item?.partner?.personContextId) && (
            <tr ref={trRef}>
              <td>{handleConcatPartnerName(item)}</td>
              <td>{handleConcatEventName(item)}</td>
              <td>{item?.attendanceStepId}</td>
              <ActionButton>
                <Button
                  color="secondary"
                  variant="text"
                  size="sm"
                  icon={<FaTrash />}
                  disabled={loadingRemove}
                  onClick={() => handleRemove(item)}
                />
              </ActionButton>
            </tr>
          )}
        </Fragment>
      )}
    />
  );
};

export default GuidedAttendancePartners;
