import propTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';
import { useFormikContext } from 'formik';
import { MdVideocamOff } from 'react-icons/md';
import { Flex, Text, ErrorMessage } from '@kiper/ui';
import PlayerActionButton from '../PlayerActionButton';
import {
  useGetCameraLive,
  useMediaAspectRatio,
  usePhotoCapture,
  usePhotoSubmission,
  useStreamStatus,
} from '../../hooks';
import SelectRegistrationCameraLocation from '../SelectRegistrationCameraLocation';
import MJPEGPlayer from '../MJPEGPlayer';
import HLSPlayer from '../../../../HLSPlayer';
import * as S from './styles';
import { cameraStreamTypes } from '../../../../../constants';

const FaceCaptureStream = ({ event, handleFallback, photo, setPhoto }) => {
  const { t } = useTranslation('guided-attendance');
  const { values, errors, touched } = useFormikContext();
  const { videoAspectRatio, capturedPhotoAspectRatio } = useMediaAspectRatio();

  const {
    url,
    cameraStreamType,
    options,
    loadingStream,
    refetchLive,
    cameraPlaceSelected,
    setCameraPlaceSelected,
  } = useGetCameraLive({ event });

  const { videoRef, capturingPhoto, captureScreenshot } = usePhotoCapture({
    videoAspectRatio,
    capturedPhotoAspectRatio,
    setPhoto,
  });

  const {
    imgRef,
    loadingLive,
    setLoadingLive,
    errorLoadingImg,
    setErrorLoadingImg,
    handleLoadImg,
    handleErrorImg,
    handleTryAgain,
  } = useStreamStatus({ setPhoto, refetchLive });

  const {
    loadingSubmitPhoto,
    photoSent,
    handleSendPhoto,
  } = usePhotoSubmission({ event });

  return (
    <S.Wrapper>
      <S.WrapperPlayer width={videoAspectRatio.width}>
        {loadingLive && <S.Spinner size={24} color="white" />}
        {!loadingStream && url ? (
          <>
            {photo ? (
              <Flex justifyContent="center" alignItems="center">
                <S.Snapshot
                  width={capturedPhotoAspectRatio.width}
                  height={capturedPhotoAspectRatio.height}
                  src={photo}
                  alt="captured-face"
                  crossOrigin="anonymous"
                />
              </Flex>
            ) : (
              <>
                {!errorLoadingImg ? (
                  <S.PlayerMask loading={+loadingLive}>
                    {cameraStreamType === cameraStreamTypes.PORTER_VIDEO && (
                      <HLSPlayer
                        ref={videoRef}
                        url={url}
                        width={videoAspectRatio.width}
                        height={videoAspectRatio.height}
                        handleLoad={handleLoadImg}
                        handleError={handleErrorImg}
                      />
                    )}
                    {cameraStreamType === cameraStreamTypes.VMS && (
                      <MJPEGPlayer
                        ref={videoRef}
                        imgRef={imgRef}
                        onLoad={handleLoadImg}
                        onError={handleErrorImg}
                        url={url}
                        width={videoAspectRatio.width}
                        height={videoAspectRatio.height}
                      />
                    )}
                  </S.PlayerMask>
                ) : (
                  <Flex
                    justifyContent="center"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <MdVideocamOff size={40} color="white" />
                    <Text fontSize="14px" color="white">
                      {t('step-photo-register.camera-unavailable-feedback')}
                    </Text>
                  </Flex>
                )}
              </>
            )}
          </>
        ) : (
          <>{!loadingLive && <S.Spinner size={24} color="white" />}</>
        )}
      </S.WrapperPlayer>

      <S.PlayerActionArea>
        {!event?.placeId && !photo && (
          <SelectRegistrationCameraLocation
            options={options}
            condominiumPersonContextId={event?.condominium?.personContextId}
            cameraPlaceSelected={cameraPlaceSelected}
            setCameraPlaceSelected={setCameraPlaceSelected}
            loadingLive={loadingLive}
            setLoadingLive={setLoadingLive}
            errorLoadingImg={errorLoadingImg}
            setErrorLoadingImg={setErrorLoadingImg}
            refetchLive={refetchLive}
            porterVideoCamera={
              cameraStreamType === cameraStreamTypes.PORTER_VIDEO
            }
          />
        )}
        <Flex
          gridGap="8px"
          flexGrow="1"
          alignItems={event?.placeId ? 'center' : 'initial'}
          justifyContent={event?.placeId ? 'center' : 'flex-end'}
        >
          {photo ? (
            <>
              <S.PlayerButton
                type="button"
                disabled={false}
                onClick={handleTryAgain}
              >
                {t('step-photo-register.try-again')}
              </S.PlayerButton>
              <S.PlayerButton
                type="button"
                disabled={loadingSubmitPhoto || photoSent}
                onClick={() => handleSendPhoto(photo)}
                success={photoSent}
              >
                <PlayerActionButton
                  photo={photo}
                  loadingLive={loadingLive}
                  errorLoadingImg={errorLoadingImg}
                  capturingPhoto={capturingPhoto}
                  loadingSubmitPhoto={loadingSubmitPhoto}
                  photoSent={photoSent}
                  values={values}
                />
              </S.PlayerButton>
            </>
          ) : (
            <S.PlayerButton
              type="button"
              disabled={capturingPhoto || loadingLive}
              onClick={() =>
                !errorLoadingImg
                  ? captureScreenshot(cameraStreamType)
                  : handleFallback()
              }
            >
              <PlayerActionButton
                photo={photo}
                loadingLive={loadingLive}
                errorLoadingImg={errorLoadingImg}
                capturingPhoto={capturingPhoto}
                loadingSubmitPhoto={loadingSubmitPhoto}
                photoSent={photoSent}
                values={values}
              />
            </S.PlayerButton>
          )}
        </Flex>
      </S.PlayerActionArea>
      {!!errors?.stepPhotoRegister?.photoRegistered &&
        !!touched?.stepPhotoRegister?.photoRegistered && (
          <ErrorMessage>
            <Trans>{errors.stepPhotoRegister.photoRegistered}</Trans>
          </ErrorMessage>
        )}
    </S.Wrapper>
  );
};

FaceCaptureStream.propTypes = {
  event: propTypes.object.isRequired,
  handleFallback: propTypes.func.isRequired,
  photo: propTypes.string,
  setPhoto: propTypes.func.isRequired,
};

FaceCaptureStream.defaultProps = {
  photo: null,
};

export default FaceCaptureStream;
