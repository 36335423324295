import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Divider, Flex, MdIcons, Text } from '@kiper/ui';
import { useGetPlaces } from '../../hooks';
import { InterlockedPlaceLink } from './styles';

const InterlockedPlaces = ({ place }) => {
  const { t } = useTranslation('guided-attendance');
  const { selectedPlace, handleSelectedPlace } = useGetPlaces();

  const handleGetInterlockedPlaces = interlockedPlaces => {
    const placesInterlockeds = interlockedPlaces.reduce((acc, current) => {
      return acc.concat(current?.places);
    }, []);

    if (!placesInterlockeds?.length) return null;
    return placesInterlockeds;
  };

  return (
    <>
      <Divider />
      <Flex width="100%" flexDirection="column" gridGap="4px">
        <Flex flexDirection="column" gridGap="4px">
          <Flex gridGap="4px" alignItems="center">
            <MdIcons mdIconName="lock" size="20px" />
            <Text fontWeight="bolder">{t('step-open-doors.interlock')}</Text>
          </Flex>
          <Flex gridGap="4px">
            {handleGetInterlockedPlaces(place?.interlocked).map(
              interlockedPlace => (
                <Flex key={interlockedPlace?.id} gridGap="8px">
                  <InterlockedPlaceLink
                    color="orange100"
                    placeSelected={selectedPlace?.id === place?.id}
                    onClick={() => handleSelectedPlace(interlockedPlace)}
                  >
                    {interlockedPlace?.name}
                  </InterlockedPlaceLink>
                </Flex>
              ),
            )}
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

export default InterlockedPlaces;

InterlockedPlaces.propTypes = {
  place: propTypes.object.isRequired,
};
