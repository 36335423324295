import { Flex, Select, Text } from '@kiper/ui';
import propTypes from 'prop-types';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocalStorage } from '../hooks';

const defaultHeight = { height: 32, minHeight: 32 };
const customStyles = {
  control: base => ({
    ...base,
    ...defaultHeight,
  }),
  valueContainer: base => ({
    ...base,
    ...defaultHeight,
  }),
  dropdownIndicator: base => ({
    ...base,
    ...defaultHeight,
    marginTop: -4,
  }),
};

const SelectRegistrationCameraLocation = ({
  options,
  cameraPlaceSelected,
  setCameraPlaceSelected,
  condominiumPersonContextId,
  loadingLive,
  setLoadingLive,
  errorLoadingImg,
  setErrorLoadingImg,
  refetchLive,
  porterVideoCamera,
}) => {
  const { setLocalStorage, getLocalStorage } = useLocalStorage();
  const { t } = useTranslation('guided-attendance');

  const handleChangeCameraPlace = placeCamera => {
    setCameraPlaceSelected(placeCamera);
    setLocalStorage(condominiumPersonContextId, placeCamera.placeId);
    setLoadingLive(true);
    if (errorLoadingImg) {
      setErrorLoadingImg(false);
    }
  };

  const handleSetInitialPlaceSelected = () => {
    const placeId = getLocalStorage(condominiumPersonContextId);
    if (placeId && options?.length) {
      const placeCamera = options.find(option => option.placeId === +placeId);
      setCameraPlaceSelected(placeCamera);
    }
  };

  useEffect(() => {
    if (errorLoadingImg && !porterVideoCamera) {
      setErrorLoadingImg(false);
      setLoadingLive(true);
      refetchLive({ variables: { condominiumPersonContextId } });
      handleSetInitialPlaceSelected();
    }
  }, [errorLoadingImg]);

  useEffect(() => {
    handleSetInitialPlaceSelected();
  }, []);

  return (
    <Flex flexDirection="column">
      <Text color="white">{t('step-photo-register.select-place')}</Text>
      <Select
        menuPlacement="top"
        placeholder=""
        options={options}
        value={cameraPlaceSelected}
        onChange={handleChangeCameraPlace}
        getOptionValue={option => option.placeId}
        getOptionLabel={option => option.placeName}
        isDisabled={loadingLive}
        styles={customStyles}
        isOptionDisabled={option =>
          option?.placeId === cameraPlaceSelected?.placeId
        }
      />
    </Flex>
  );
};

export default SelectRegistrationCameraLocation;

SelectRegistrationCameraLocation.propTypes = {
  options: propTypes.array,
  cameraPlaceSelected: propTypes.object,
  condominiumPersonContextId: propTypes.number.isRequired,
  setCameraPlaceSelected: propTypes.func.isRequired,
  loadingLive: propTypes.bool.isRequired,
  setLoadingLive: propTypes.func.isRequired,
  errorLoadingImg: propTypes.bool.isRequired,
  setErrorLoadingImg: propTypes.func.isRequired,
  refetchLive: propTypes.func.isRequired,
  porterVideoCamera: propTypes.bool,
};

SelectRegistrationCameraLocation.defaultProps = {
  options: [],
  cameraPlaceSelected: '',
  porterVideoCamera: false,
};
