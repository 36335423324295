import { useEffect, useMemo, useState } from 'react';
import { useLazyQuery } from 'react-apollo';
import { getCondominiumCameraLiveV2 } from '@kiper/monitoring-graphql/guided_attendance';
import useLocalStorage from './useLocalStorage';

const useGetCameraLive = ({ event }) => {
  const [cameraPlaceSelected, setCameraPlaceSelected] = useState(null);
  const { getLocalStorage } = useLocalStorage();

  const [
    getCondominiumCameraLive,
    { data: liveData, loading: loadingStream, refetch: refetchLive },
  ] = useLazyQuery(getCondominiumCameraLiveV2, {
    fetchPolicy: 'no-cache',
    onCompleted: ({ condominiumCameraLiveV2 }) => {
      if (!condominiumCameraLiveV2?.length) return;

      const placeIdLocalStorage = getLocalStorage(
        event?.condominium?.personContextId,
      );
      const placeId = event?.placeId || placeIdLocalStorage;

      const placeCamera = placeId
        ? condominiumCameraLiveV2.find(option => option.placeId === +placeId)
        : null;

      setCameraPlaceSelected(placeCamera || condominiumCameraLiveV2[0]);
    },
  });

  const { url, cameraStreamType } = useMemo(
    () => ({
      url: cameraPlaceSelected?.url,
      cameraStreamType: cameraPlaceSelected?.cameraType,
    }),
    [cameraPlaceSelected],
  );
  const options = useMemo(() => liveData?.condominiumCameraLiveV2, [liveData]);

  useEffect(() => {
    if (event?.condominium?.personContextId) {
      getCondominiumCameraLive({
        variables: {
          condominiumPersonContextId: event?.condominium?.personContextId,
        },
      });
    }
  }, [event?.condominium?.personContextId]);

  return {
    url,
    cameraStreamType,
    options,
    loadingStream,
    refetchLive,
    cameraPlaceSelected,
    setCameraPlaceSelected,
  };
};

export default useGetCameraLive;
